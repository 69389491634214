import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { defaultTheme } from "./theme/defaultTheme";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "./components/common/ConfirmationDialog";
import { CartContextProvider } from "./context/CartContext";

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="top-center"
      autoClose={10000}
      hideProgressBar
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <CartContextProvider>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <ConfirmationDialog>
          <App />
        </ConfirmationDialog>
      </ThemeProvider>
    </CartContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
