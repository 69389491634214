import ReactFullpage from "@fullpage/react-fullpage";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { useMatch, useNavigate } from "react-router";
import ReactPlayer from "react-player";

function Studios(props) {
  const classes = useStyle();
  const [isVerified, setIsVerified] = useState(false);
  const match = useMatch("/verify-account/:token");

  const navigate = useNavigate();
  useEffect(() => {
    window?.lazyLoad();
  }, []);

  return (
    <div>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-146650847-1"
        ></script>

        <title>Studio DMI | Studios</title>
        <meta charset="UTF-8" />
        <meta name="description" content="Since its inception in 2012, Studio DMI is the physical embodiment of Luca Pretolesi’s lifetime dedicated to music. Our state-of-the-art facility located in Las Vegas, NV, features four studios powered by Focal and functions as a showroom of Focal products includes 1 Production studio, our Dolby-endorsed Atmos Mixing Studio, and 2 Mixing and Mastering studios, each incorporating unrivaled and constantly evolving technology that shapes our signature style and sound." />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="images/favicon.png"
        />

        <link
          type="text/css"
          rel="stylesheet"
          href="css/main.css?v=022621"
          media="all"
        />
        <script type="text/javascript" src="js/jquery-3.4.1.min.js"></script>
        <script type="text/javascript" src="js/fullpage.min.js"></script>
        <script type="text/javascript" src="js/slick.min.js"></script>
        <script type="text/javascript" src="js/scrollreal.min.js"></script>
        <script
          type="text/javascript"
          src="js/jquery.magnific-popup.min.js"
        ></script>
        <script
          type="text/javascript"
          src="js/jquery.mCustomScrollbar.concat.min.js"
        ></script>
        <script type="text/javascript" src="js/jcf.js"></script>
        <script type="text/javascript" src="js/jcf.checkbox.js"></script>
        <script type="text/javascript" src="js/main.js"></script>
        {/* <script type="text/javascript" src="js/main.js"></script> */}
      </Helmet>

      <div className="page-wrap">
        {/* <header className="main-header">
          <div className="header-inner">
            <a href="#" className="btn-menu" title="">
              <span></span>
            </a>
            <div className="logo-holder">
              <a href="/" className="logo-holder">
                <img src="images/logo-studio-dmi-white-red.png" alt="" />
              </a>
            </div>
            <div className="nav-holder">
              <nav className="main-nav">
                <ul className="no-style">
                  <li className="active">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="featured-work">Featured Work</a>
                  </li>
                  <li>
                    <a href="studios">Studios</a>
                  </li>
                  <li>
                    <a href="edu">EDU</a>
                  </li>
                  <li>
                    <a href="tech">Tech</a>
                  </li>
                  <li>
                    <a href="https://media.studiodmi.com">Media</a>
                  </li>
                </ul>
              </nav>
              <div className="button-holder">
                <a href="/#studio-services">Start your project</a>
              </div>
            </div>
          </div>
        </header> */}
        <div>
          <ReactFullpage
            //fullpage options
            licenseKey={"B1B8F4FA-C4BC47C2-BB0BD453-8FCE1D29"}
            scrollingSpeed={1000} /* Options here */
            anchors={[
              "studio-dmi",
              "studio-d",
              "studio-m",
              "studio-i",
              "studio-r",
            ]}
            navigationTooltips={[
              "studio dmi",
              "studio d",
              "studio m",
              "studio i",
              "studio r",
            ]}
            showActiveTooltip={true}
            responsiveWidth={800}
            navigationPosition={"left"}
            menu={"#fpmenu"}
            navigation={true}
            scrollBar={true}
            css3={true}
            render={({ state, fullpageApi }) => {
              console.log(state);
              let indexSection = null;
              indexSection = fullpageApi?.getActiveSection().index;

              return (
                <ReactFullpage.Wrapper>
                  <div className="section studios-section">
                    <div
                      className="card-image bgr-style mobile-hide"
                      style={{
                        backgroundImage:
                          "url(images/EXTERIOR.jpeg)"
                      }}
                      data-image-full="images/EXTERIOR.jpeg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div
                      className="card-image bgr-style mobile-show"
                      style={{
                        backgroundImage:
                          "url(images/studios-m.jpeg)",
                      }}
                      data-image-full="images/studios-m.jpeg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container">
                      <div className={classes.frontInfo}>
                        <div className="studios-section-wrap">
                          <div className="left-col">
                            <Fade
                              bottom
                              delay={250}
                              duration={1000}
                              when={
                                indexSection == 0 || window.innerWidth < 800
                              }
                            >
                              <h1 className="h2-title" >Make Music Better</h1>
                            </Fade>
                            <div className="studio-features-wrap">
                              <Fade
                                bottom
                                delay={700}
                                duration={1000}
                                when={
                                  indexSection == 0 || window.innerWidth < 800
                                }
                              >
                                <div className="studio-feature ">
                                  <div className="number-holder">2</div>
                                  <div className="text-holder">
                                    Mixing and <br />
                                    Mastering Studios
                                  </div>
                                </div>
                              </Fade>
                              <Fade
                                bottom
                                delay={1000}
                                duration={1000}
                                when={
                                  indexSection == 0 || window.innerWidth < 800
                                }
                              >
                                <div className="studio-feature ">
                                  <div className="number-holder">1</div>
                                  <div className="text-holder">
                                    Production <br />
                                    Studio
                                  </div>
                                </div>
                              </Fade>
                              <Fade
                                bottom
                                delay={1500}
                                duration={1000}
                                when={
                                  indexSection == 0 || window.innerWidth < 800
                                }
                              >
                                <div className="studio-feature">
                                  <div className="number-holder">1</div>
                                  <div className="text-holder">
                                    Spatial mixing <br />
                                    Studio
                                  </div>
                                </div>
                              </Fade>
                            </div>
                          </div>
                          <Fade
                            bottom
                            distance="20px"
                            delay={1800}
                            duration={1000}
                            when={indexSection == 0 || window.innerWidth < 800}
                          >
                            <div className="right-col">
                              <p>
                                Since its inception in 2012, Studio DMI is the physical embodiment of Luca Pretolesi's lifetime dedicated to music.
                                Our state-of-the-art facility located in Las Vegas, NV, features four studios powered by Focal and functions as a
                                showroom of Focal products includes 1 Production studio, our Dolby-endorsed Atmos Mixing Studio,
                                and 2 Mixing and Mastering studios, each incorporating unrivaled and constantly evolving technology that shapes
                                our signature style and sound.
                                {/* Opening in late 2019, Studio DMI is the physical
                                embodiment of Luca Pretolesi’s lifetime
                                dedicated to music.
                                <br /> Our state-of-the-art facility located in
                                Las Vegas, NV, features four studios powered by
                                Focal including 1 Production and  spatial mixing studio,
                                our brand-new Spatial Audio Mixing
                                Studio and 2 Mixing and Mastering studios, each
                                incorporating unrivaled and constantly evolving
                                technology that shapes our signature style and
                                sound. */}
                              </p>
                              {/* <p>
                              Exercitation ullamco laboris nisi ut aliquip ex ea
                              commodo consequat. Duis aute irure dolor in
                              reprehenderit in voluptate velit esse cillum
                              dolore eu fugiat nulla...{" "}
                              <a
                                href="#about-studio-modal"
                                className="read-more open-modal"
                              >
                                Read more
                              </a>
                            </p> */}
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                    <div className="scroll-down-holder ">
                      <a href="#studio-d" className="scroll-down">
                        <img src="images/svg/caret-down.svg" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="section studio-single studio-single-left">
                    <div
                      className="card-image bgr-style"
                      style={{
                        backgroundImage: "url(images/bgr-STUDIO-D.jpeg)",
                      }}
                      data-image-full="images/bgr-STUDIO-D.jpeg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container">
                      <div className="studio-single-background">
                        <div className={classes.playerDWrapper}>
                          <iframe
                            src="https://player.vimeo.com/video/741221808?h=58e24b42ae&title=0&byline=0&portrait=0&app_id=58479"
                            width="640"
                            height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                            className={classes.playerD}
                          ></iframe>
                        </div>
                        <div className="studio-single-left-wrap">
                          <Fade
                            bottom
                            delay={250}
                            duration={1000}
                            when={indexSection == 1 || window.innerWidth < 800}
                          >
                            <h2>Studio D</h2>
                          </Fade>
                          <Fade
                            bottom
                            delay={600}
                            duration={1000}
                            when={indexSection == 1 || window.innerWidth < 800}
                          >
                            <p>
                              Las Vegas' premier Spatial Audio Studio and partner studio of Dolby powered by an incredible 11 x 4 Focal
                              System (Focal Solo 6 x 8, Focal Trio 11 x 3, Focal Sub 6 x 4). It also contains custom acoustic treatments
                              by Vicoustic, and a Pro Tools MTRX Studio System.The design was completed in the Spring of 2022,
                              spearheaded by Haverstick Designs, with the system design by Studio DMI's Luca Pretolesi and Focal Audio's
                              Joshua Estock. Studio D was also awarded "Class of 2022's World’s Best Studios" by Mix Magazine.
                              {/* Currently Las Vegas' first, and only Spatial Audio
                              Studio endorsed by Dolby and powered by an
                              incredible 11 x 4 Focal System (Focal Solo 6 x 8,
                              Focal Trio 11 x 3, Focal Sub 6 x 4).
                              <br /> It also contains custom acoustic treatments
                              by Vicoustic, and a Pro Tools MTRX Studio System.
                              <br /> The design was completed in the Spring of
                              2022, spearheaded by Haverstick Designs, with the
                              system design by Studio DMI's Luca Pretolesi and
                              Focal Audio's Joshua Estock.
                              <br /> Studio D was also recently awarded "Class
                              of 2022's Best Studios" by Mix Magazine. */}
                            </p>
                          </Fade>
                          <Fade
                            bottom
                            delay={1000}
                            duration={1000}
                            when={indexSection == 1 || window.innerWidth < 800}
                          >
                            <div className="">
                              <a
                                href="/#studio-services"
                                className="btn-primary"
                              >
                                Start your project
                              </a>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                    <div className="scroll-down-holder ">
                      <a href="#studio-i" className="scroll-down">
                        <img src="images/svg/caret-down.svg" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="section studio-single studio-single-left">
                    <div
                      className="card-image bgr-style"
                      style={{
                        backgroundImage:
                          "url(images/bgr-slide-studio-m-low.jpg)",
                      }}
                      data-image-full="images/bgr-slide-studio-m.jpg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container">
                      <div className="studio-single-background">
                        <div className="studio-single-left-wrap">
                          <Fade
                            bottom
                            delay={250}
                            duration={1000}
                            when={indexSection == 2 || window.innerWidth < 800}
                          >
                            <h2>Studio M</h2>
                          </Fade>
                          <Fade
                            bottom
                            delay={600}
                            duration={1000}
                            when={indexSection == 2 || window.innerWidth < 800}
                          >
                            <p>
                              A highly accurate digital studio constructed for
                              both mixing and mastering. Features Acoustic
                              treatment by Gavin Haverstick in collaboration
                              with Vicoustic.
                            </p>
                          </Fade>
                          <Fade
                            bottom
                            delay={1000}
                            duration={1000}
                            when={indexSection == 2 || window.innerWidth < 800}
                          >
                            <div className="">
                              <a
                                href="/#studio-services"
                                className="btn-primary"
                              >
                                Start your project
                              </a>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                    <div className="scroll-down-holder ">
                      <a href="#studio-i" className="scroll-down">
                        <img src="images/svg/caret-down.svg" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="section studio-single studio-single-bottom">
                    <div
                      className="card-image bgr-style"
                      style={{
                        backgroundImage:
                          "url(images/bgr-slide-studio-i-low.jpg)",
                      }}
                      data-image-full="images/bgr-slide-studio-i.jpg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container">
                      <div className="studio-single-background">
                        <div className="studio-single-bottom-wrap">
                          <div className="left-col ">
                            <Fade
                              bottom
                              delay={250}
                              duration={1000}
                              when={
                                indexSection == 3 || window.innerWidth < 800
                              }
                            >
                              <h2>Studio I</h2>
                            </Fade>
                            <Fade
                              bottom
                              delay={600}
                              duration={1000}
                              when={
                                indexSection == 3 || window.innerWidth < 800
                              }
                            >
                              <a
                                href="/#studio-services"
                                className="btn-primary"
                              >
                                Start your project
                              </a>
                            </Fade>
                          </div>
                          <Fade
                            bottom
                            delay={1000}
                            duration={1000}
                            when={indexSection == 3 || window.innerWidth < 800}
                          >
                            <div className="right-col ">
                              <p>
                                A hybrid analog/digital mixing and mastering
                                room equipped with a combination of vintage and
                                modern gear, and plugins.
                              </p>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="section studio-single studio-single-bottom"
                    style={{ overflow: "hidden" }}
                  >
                    <div
                      className="card-image bgr-style"
                      style={{
                        backgroundImage: "url(images/bgr-slide-studio-r.jpeg)",
                      }}
                      data-image-full="images/bgr-slide-studio-r.jpeg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container">
                      <div className="studio-single-background">
                        <div className="studio-single-bottom-wrap">

                          {/* <p>
                              <a href="https://vimeo.com/708237079">
                                Studio R
                              </a>{" "}
                             
                            </p> */}
                          <div className="left-col">
                            <Fade
                              bottom
                              delay={250}
                              duration={1000}
                              when={
                                indexSection == 4 || window.innerWidth < 800
                              }
                            >
                              <h2>Studio R</h2>
                            </Fade>

                            <Fade
                              bottom
                              delay={600}
                              duration={1000}
                              when={
                                indexSection == 4 || window.innerWidth < 800
                              }
                            >
                              <a
                                href="/#studio-services"
                                className="btn-primary"
                              >
                                Start your project
                              </a>
                            </Fade>

                          </div>


                          <Fade
                            bottom
                            delay={1000}
                            duration={1000}
                            when={indexSection == 4 || window.innerWidth < 800}
                          >
                            <div className="right-col">
                              <p>
                                A collaborative workspace for producers with a
                                state of the art vocal booth geared towards
                                writing camps and group production.
                              </p>
                              <div className={classes.playerContainer}>
                                <div className={classes.playerWrapper}>
                                  <iframe
                                    src="https://player.vimeo.com/video/708237079?h=759e00b1fd&title=0&byline=0&portrait=0"
                                    width="640"
                                    height="360"
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowfullscreen
                                    className={classes.player}
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactFullpage.Wrapper>
              );
            }}
          />
        </div>
        {/* <div className="modal-primary mfp-hide" id="luca-pretolesi-modal">
          <div className="modal-primary-wrap">
            <div className="left-col">
              <h3 className="h1-title">Luca Pretolesi</h3>
              <ul className="no-style awards">
                <li>
                  <img src="images/award-3.png" alt="" />{" "}
                </li>
                <li>
                  <img src="images/award-1.png" alt="" />
                </li>
                <li>
                  <img src="images/award-2.png" alt="" />
                </li>
                <li>
                  <img src="images/award-4.png" alt="" />
                </li>
              </ul>
            </div>
            <div className="right-col scroll-right text-engineer-modal">
              <p>
              Logging well over 40,000 hours in the studio with over 25 years of experience, 
              Luca Pretolesi’s prolific career has led him to his status as the world-renowned 
              mixing and mastering engineer he’s known as today. With awards ranging from Grammy wins 
              & multiple nominations, Platinum, Diamond, Billboard, and Golden Melody awards as well 
              as founding and creating Studio DMI, his state-of-the-art facility based in Las Vegas, 
              NV USA, Luca, and his Studio DMI brand are synonymous with unrivaled quality and style 
              sought out by the biggest names in electronic and pop music.
              </p>
              <p>
                Luca has had the honor of working with top artists around the
                globe such as Steve Aoki, Diplo, David Guetta, J Balvin, Snoop
                Lion, Above &amp; Beyond, G-Dragon, Jolin Tsai and many more.
                Luca&#39;s Grammy nominations include J Balvin &amp; Willy
                William&#39;s &quot;Mi Gente&quot; for Record of the Year, Steve
                Aoki&#39;s &quot;Wonderland&quot; for Best Dance Album, Snoop
                Lion&#39;s &quot;Reincarnation&quot; for Best Reggae Album.
              </p>
              <p>
                Studio DMI Highlighted Credits: Above &amp; Beyond, Alok, Baby
                K, Bassjackers, Benny Benassi, BIGBANG, BLACKPINK, Borgeous,
                Borgore, Bruno Martini, Cedric Gervais, Dada Life, David Guetta,
                Dillon Francis, Dimitri Vegas &amp; Like Mike, Diplo, DJ
                Shiftee, DVBBS, Fabio Rovazzi, Flo Rida, FTampa, Gareth Emery,
                Gianluca Vacchi, G-Dragon, Jason Derulo, J Balvin, Jolin Tsai,
                Jovanotti, K?D, KSHMR, Kura, Lil Jon, MAKJ, Major Lazer, Merk
                &amp; Kremont, Morgan Page, Moti, Nervo, NGHTMRE, Paul
                Oakenfold, Pegboard Nerds, Play-N-Skillz, Rusko, Sandro Cavazza,
                SAYMYNAME, Snoop Lion, Sofi Tukker, Steve Aoki, TJR, Willy
                William, Zeds Dead, 2NE1.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div
        id="fp-nav"
        className="fp-left fp-show-active"
        style={{ marginTop: -93 }}
      >
        <ul>
          <li>
            <a data-menuanchor="studio-dmi" href="#studio-dmi"></a>
          </li>
          <li>
            <a data-menuanchor="luca-pretolesi" href="#luca-pretolesi"></a>
          </li>
        </ul>
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    left: 0,
    padding: "20px 100px",
  },
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
  playerContainer: {
    width: "100%",
  },
  playerWrapper: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    paddingTop: "56.25%",
    marginTop: "24px"
  },
  player: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  playerD: {
    position: "relative",
    left: 48,
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      position: "relative",
      width: "100%",
      left: 0
    }
  },
  playerDWrapper: {
    position: "absolute",
    left: 64,
    bottom: 64,
    width: 480,
    height: 360,
    [theme.breakpoints.down('md')]: {
      position: "relative",
      width: "100%",
      left: 0,
      bottom: 24,
    }
  },
  frontInfo: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: 120
    }
  }
}));

export default Studios;
