import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const IconSuccess = (props) => {
  return (
    <SvgIcon
      width="232"
      height="289"
      viewBox="0 0 232 289"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M195.785 95.7977H177.48V34.6684L166.437 24.4263L158.038 32.2408L149.64 24.4263L141.218 32.2408L132.82 24.4263L124.398 32.2408L116 24.4263L107.602 32.2408L99.18 24.4263L90.7816 32.2408L82.36 24.4263L73.9616 32.2408L65.5632 24.4263L54.52 34.6684V95.7977H36.2152C16.2168 95.7977 0 111.959 0 131.888V206.774H232V131.888C232 111.959 215.783 95.7977 195.785 95.7977ZM59.16 36.703L65.5632 30.7612L73.9616 38.5526L82.36 30.7612L90.7816 38.5526L99.18 30.7612L107.602 38.5526L116 30.7612L124.398 38.5526L132.82 30.7612L141.218 38.5526L149.64 30.7612L158.038 38.5526L166.437 30.7612L172.84 36.703V137.414H59.16V36.703ZM227.36 202.15H4.64V131.888C4.64 114.548 18.8152 100.422 36.2152 100.422H54.52V128.859C53.36 129.322 52.316 130.015 51.4576 130.894C49.764 132.559 48.72 134.871 48.72 137.414C48.72 142.5 52.896 146.662 58 146.662H174C176.552 146.662 178.872 145.621 180.542 143.934C182.236 142.269 183.28 139.957 183.28 137.414C183.28 133.553 180.867 130.223 177.48 128.859V100.422H195.785C213.185 100.422 227.36 114.548 227.36 131.888V202.15Z"
        fill="#1A1A30"
      />
      <path
        d="M148.667 57.8485C146.856 56.0429 143.918 56.0429 142.106 57.8485L101.305 98.5074L89.8926 87.1347C88.083 85.329 85.1435 85.329 83.3316 87.1347C81.5197 88.9403 81.5197 91.8673 83.3316 93.673L101.305 111.586L148.667 64.3892C150.479 62.5812 150.479 59.6542 148.667 57.8485Z"
        fill="#E2211C"
      />
    </SvgIcon>
  );
};
export default IconSuccess;
