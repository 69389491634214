import { Box, Typography, Grid, Container, ImageListItem, ImageListItemBar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMatch, useNavigate } from "react-router";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const trackList = [
    {
        trackUrl: "https://open.spotify.com/track/79uf4T9oqDNjPKd8eCKtha?si=314d6192c8dd4f77",
        imgSrc: "images/fw/With You.jpg",
        title: "With You",
        subtitle: "Gordo & Adriatique",
    },
    {
        trackUrl: "https://open.spotify.com/album/72GuMt9GOvytS3yxco7qQ0?si=T1GTAoUHQ72PY4L2yOoZpw",
        imgSrc: "images/fw/Breathwork.jpg",
        title: "Breathwork",
        subtitle: "Austin Millz",
    },
    {
        trackUrl: "https://open.spotify.com/track/0BWb9vQfDA3d8WskFTvUUE?si=71ebd63a94ee4014",
        imgSrc: "images/fw/Motion.jpg",
        title: "Motion",
        subtitle: "Ty Dolla $ign Feat. Chris Brown",
    },
    {
        trackUrl: "https://open.spotify.com/album/7Bv6Vg8y1Cwl7b9TV1xSqQ?si=AxtOoQ1FSjSll9OgAPFyGg",
        imgSrc: "images/fw/Piano Republik.jpg",
        title: "Piano Republik",
        subtitle: "Major Lazer & Major League DJz",
    },
    {
        trackUrl: "https://open.spotify.com/track/6gs5VDiNpbBfXHsAj2c9SB?si=3e2098f1e3684c79",
        imgSrc: "images/fw/Fade Out.jpg",
        title: "Fade Out",
        subtitle: "John Summit Feat. MKLA",
    },
    {
        trackUrl: "https://open.spotify.com/track/4zbInBD4rY7tYPJ16LVxdh?si=fb79650487b44f85",
        imgSrc: "images/fw/Badders.jpg",
        title: "Badders",
        subtitle: "PEEKABOO, Flowdan & Skrillex",
    },
    {
        trackUrl: "https://open.spotify.com/album/4kI7ZZF6CgDGFTjZNFwXYG?si=V4dKUrHgQ2aPK-yqXuJDiQ",
        imgSrc: "images/fw/Portals.jpg",
        title: "Portals",
        subtitle: "Melanie Martinez",
    },
    {
        trackUrl: "https://open.spotify.com/album/5TG8nNzWlr4lsL6XBURDs0",
        imgSrc: "images/fw/Dont Get Too Close.jpg",
        title: "Don't Get Too Close",
        subtitle: "Skrillex",
    },
    {
        trackUrl: "https://open.spotify.com/album/7tWP3OG5dWphctKg4NMACt",
        imgSrc: "images/fw/Quest For Fire.jpg",
        title: "Quest For Fire",
        subtitle: "Skrillex",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/4xxOx87AD6mBqU3TsuRfg3?si=35f12447644646c6",
        imgSrc: "images/fw/RealSpring.jpg",
        title: "Real Spring",
        subtitle: "Skrillex, Bladee",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/1GfBLbAhZUWdseuDqhocmn?si=7a396dbfb0a94f0e",
        imgSrc: "images/fw/Rumble.jpg",
        title: "Rumble",
        subtitle: "Skrillex, Fred again, Flowdan",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/2mobDDzkskezluhIzhM7Sg?si=aecc0ad1f81f4827",
        imgSrc: "images/fw/WayBack.jpg",
        title: "Way Back",
        subtitle: "Skrillex, PinkPantheress, Trippie Redd",
    },
    {
        trackUrl: "https://open.spotify.com/album/3cf4iSSKd8ffTncbtKljXw",
        imgSrc: "images/fw/Drake - Honestly, Nevermind.jpeg",
        title: "Honestly, Nevermind",
        subtitle: "Drake",
    },
    {
        trackUrl: "https://open.spotify.com/album/5pdR4YX2zWMXotdF034UYY",
        imgSrc: "images/fw/Diplo - Diplo.jpeg",
        title: "Diplo",
        subtitle: "Diplo",
    },
    {
        trackUrl: "https://open.spotify.com/album/4Z1gPMu3kxL1P8rMGHc5ZX",
        imgSrc: "images/fw/Gareth Emery - Analog.png",
        title: "Analog",
        subtitle: "Gareth Emery",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/73hU9Ye6vhw2STDJDwkviE?si=2e6b617c0238469a",
        imgSrc: "images/fw/Mathame - “So What”.webp",
        title: "So What",
        subtitle: "Mathame",
    },
    {
        trackUrl: "https://open.spotify.com/album/7h1PPe6YeLHGSu9gLRzQLj",
        imgSrc: "images/fw/Alan Walker - “Extremes”.jpeg",
        title: "Extremes",
        subtitle: "Alan Walker",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/3C7notXz5L0t5EbaJVfubp?si=1fe8f23c7916468d",
        imgSrc: "images/fw/Euro Trash, Wax Motif - “So Hypnotic”.jpeg",
        title: "So Hypnotic",
        subtitle: "Euro Trash, Wax Motif",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/1rGd2CoMxEg2dnaytLY5ZG?si=aeaf8306d7674988",
        imgSrc: "images/fw/Dillon Francis - “Goodies”.jpeg",
        title: "Goodies",
        subtitle: "Dillon Francis",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/7lNfu3VuTld2l9jZCjbv6E?si=b634560e55744b85",
        imgSrc: "images/fw/Muni Long, Saweetie - “Baby Boo” Remix.jpeg",
        title: "Baby Boo",
        subtitle: "Muni Long, Saweetie",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/0Kh1JKeJMAvFxv91HZ8uYU?si=dbf059fddaaa433a",
        imgSrc: "images/fw/Wax Motif - “Bump Bump Bump”.jpeg",
        title: "Bump Bump Bump",
        subtitle: "Wax Motif",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/1GJpFJT50Compi92OH2Yhz?si=909fb26c5c624a95",
        imgSrc: "images/fw/Diplo, Swae Lee - “Tupelo Shuffle” .jpeg",
        title: "Tupelo Shuffle",
        subtitle: "Diplo, Swae Lee",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/4tiEfGlJaonFDtSTCvesoj?si=8e81c4208cbb40e3",
        imgSrc: "images/fw/Chiquis - “Abeja Reina” .jpeg",
        title: "Abeja Reina",
        subtitle: "Chiquis",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/1Lim1Py7xBgbAkAys3AGAG?si=D447S7jpTry0p7oeaU1dzw",
        imgSrc: "images/card-lean-on.jpg",
        title: "Lean On",
        subtitle: "Major Lazer",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/4ipnJyDU3Lq15qBAYNqlqK?si=qdNuwDGSRUWtHfWdZcJg9Q",
        imgSrc: "images/card-mi-gente.jpg",
        title: "Mi Gente",
        subtitle: "J Balvin",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/5lXY6PTuWXOludKy4zDQwM?si=KL7yi5P8T8C8qdazelk3xw",
        imgSrc: "images/card-on-my-mind.jpg",
        title: "On My Mind",
        subtitle: "Diplo",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/3yHQKddM8SVCRnuPSo3HPN?si=zCHxiHdAQYGUZxGpjKynrw",
        imgSrc: "images/card-boombayah.jpg",
        title: "Boombayah",
        subtitle: "BlackPink",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/0cONHWTIfgEpAuVwbbwGgc?si=own4wkMNQpqWtBpjHcA1zQ",
        imgSrc: "images/card-ugly-beauty.jpg",
        title: "Ugly Beauty",
        subtitle: "Jolin Tsai",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/7MRU4vOkywuhZ9kbFiPuiu?si=R-cjieszTm2i83WIjWmUSQ",
        imgSrc: "images/card-si-supieras.jpg",
        title: "Si Supieras",
        subtitle: "Daddy Yankee",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/5Xn4IyTtW6FUGIUyWjbUHG?si=OnS4f4XrRsyIyW6VwLognw",
        imgSrc: "images/card-goodbye.jpg",
        title: "Goodbye",
        subtitle: "Jason Derulo",
    },
    {
        trackUrl:
            "https://open.spotify.com/track/0id7TrBjYYzQ5v5mHWTUxC?si=NP9YZgqxT8ypizOI_mEomw",
        imgSrc: "images/card-follow.jpg",
        title: "Follow",
        subtitle: "Kito, Zhu",
    },
];

function FeaturedWork(props) {
    const classNamees = useStyle();
    const [isVerified, setIsVerified] = useState(false);
    const match = useMatch("/verify-account/:token");

    const navigate = useNavigate();
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <>
                <div
                    className={className}
                    style={{ ...style, display: "none" }}
                    onClick={onClick}
                ></div>
                <button class="btn-slide next-slide" onClick={onClick}>
                    <img src="images/slider-arrow-right.png" alt="" />
                </button>
            </>
        );
    }

    useEffect(() => {
        window?.lazyLoad();
    }, []);

    return (
        <div>
            <Helmet>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=UA-146650847-1"
                ></script>

                <title>Studio DMI | Featured Work</title>
                <meta charset="UTF-8" />
                <meta name="description" content="" />
                <meta name="referrer" content="origin" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="images/favicon.png"
                />

                <link
                    type="text/css"
                    rel="stylesheet"
                    href="css/main.css?v=022621"
                    media="all"
                />
                <script type="text/javascript" src="js/scrollreal.min.js"></script>
            </Helmet>
            <div className="page-wrap">
                <div className="section highlighted-work-section">
                    <div
                        className="card-image bgr-style mobile-hide"
                        style={{
                            backgroudImage: "url(images/bgr-slide-showcase-low.jpg)",
                        }}
                        data-image-full="images/bgr-slide-showcase.jpg"
                    >
                        <img className="back-img" src="" alt="" />
                    </div>
                    <div
                        className="card-image bgr-style mobile-show"
                        style={{
                            backgroudImage:
                                "url(images/bgr-slide-showcase-mobile2-low.jpg)",
                        }}
                        data-image-full="images/bgr-slide-showcase-mobile2.jpg"
                    >
                        <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
                        <h2 className="h3-title r-1">Showcase</h2>
                        <Container maxWidth={"xl"}>
                            <Grid container spacing={0} alignContent={"center"} justifyContent={"center"}>
                                {trackList.map((item) => (
                                    <Grid item xs={6} sm={3} lg={3}>
                                        <TrackItem
                                            trackUrl={item.trackUrl}
                                            imgSrc={item.imgSrc}
                                            title={item.title}
                                            subtitle={item.subtitle}
                                        />
                                    </Grid>
                                ))}



                            </Grid>
                        </Container>
                    </div>
                    {/* </Zoom>
                                </Fade> */}
                </div>

            </div>
            <Box
                className={classNamees.onlineMentoring}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="button" color="textSecondary">
                        ALL RIGHTS RESERVED ©StudioDMI {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
        </div>

    );
}

const useStyle = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: "#15293A",
        position: "relative",
    },
    onlineMentoring: {
        backgroundColor: theme.palette.background.main,
        margin: 0,
        padding: 20
    },
    backdropOverlay: {
        position: "absolute",
        top: 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        left: 0,
        padding: "20px 100px",
    },
    root: {
        backgroundColor: "#fff",
        height: "100%",
    },
    promoCode: {
        backgroundColor: theme.palette.secondary.light,
        padding: "8px 28px",
        marginTop: "316px",
    },
    payPalSelect: {
        width: "100%",
        border: "1px solid #A5B0BE",
        borderRadius: "4px",
        boxSizing: "border-box",
    },
    textBtn: {
        color: "#AAC9DD",
    },
}));

export default FeaturedWork;

const TrackItem = ({ trackUrl, imgSrc, title, subtitle }) => {

    const [isHovered, setIsHovered] = useState(false)

    return (
        <a href={trackUrl} target="_blank">
            <ImageListItem onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)} >
                <img

                    src={imgSrc}
                    alt={title}
                    loading="lazy"
                />
                {isHovered ? <ImageListItemBar
                    title={title}
                    subtitle={subtitle}

                /> : ""}
            </ImageListItem>
        </a>
        // <div>
        //     <a href={trackUrl} target="_blank">
        //         <div >
        //             <div >
        //                 <img src={imgSrc} style={{width:"100%"}} alt="" />
        //             </div>
        //             {/* <div className="text-holder">
        //                 <h6>{subtitle}</h6>
        //                 <h3>{title}</h3>
        //             </div> */}
        //         </div>
        //     </a>
        // </div>
    );
}
