import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const IconHelp = (props) => {
  return (
    <SvgIcon
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 11H9.25C8.65625 11.2812 7.65625 11.5 7 11.5C6.3125 11.5 5.3125 11.2812 4.71875 11H4C1.78125 11 0 12.8125 0 15C0 15.5625 0.4375 16 1 16H13C13.5312 16 14 15.5625 14 15C14 12.8125 12.1875 11 10 11ZM1.5 7C1.75 7 2 6.78125 2 6.5V6C2 3.25 4.21875 1 7 1C9.75 1 12 3.25 12 6V6.5C11.9688 7.90625 10.875 9 9.5 9H8.5C8.5 8.46875 8.03125 8 7.5 8H6.5C5.9375 8 5.5 8.46875 5.5 9C5.5 9.5625 5.9375 10 6.5 10H9.5C11.4062 10 12.9688 8.4375 13 6.5V6C13 2.71875 10.2812 0 7 0C3.6875 0 1 2.71875 1 6V6.5C1 6.78125 1.21875 7 1.5 7ZM6.5 7H7.5C8.125 7.03125 8.90625 7.46875 9.21875 8H9.5C10.3125 8 10.9688 7.34375 11 6.5V6C11 3.8125 9.1875 2 7 2C4.78125 2 3 3.8125 3 6C3 7.28125 3.59375 8.375 4.5 9.125C4.5 9.09375 4.5 9.0625 4.5 9C4.5 7.90625 5.375 7.03125 6.5 7Z"
        fill="#AAC9DD"
      />
    </SvgIcon>
  );
};
export default IconHelp;
