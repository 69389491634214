import { useEffect } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { createOrder, approveOrder } from "../../redux/action/order.action";
import { useDispatch } from "react-redux";
// This values are the props in the UI
const amount = "2";
const currency = "USD";
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner, items, onSuccess, coupon }) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  console.log("coupon inside button", coupon)

  const dispatch2 = useDispatch();
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner, coupon]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return dispatch2(createOrder(items, coupon)).then((id) => {
            return id;
          });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            dispatch2(approveOrder(data.orderID))
              .then((res) => onSuccess())
              .catch((Err) => console.log(Err));
          });
        }}
      />
    </>
  );
};

export default function Paypal(props) {
  return (
    <div style={{ maxWidth: "750px", minHeight: "200px" }}>
      <PayPalScriptProvider
        options={{
          "client-id":
            `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
          components: "buttons",
          currency: "USD",
        }}
      >
        <ButtonWrapper
          currency={currency}
          showSpinner={false}
          items={props.items}
          onSuccess={props.onSuccess}
          coupon={props.coupon}
        />
      </PayPalScriptProvider>
    </div>
  );
}
