import AddCircle from "@mui/icons-material/AddCircle";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { groupBy } from "lodash";
import { toast } from "material-react-toastify";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import { addCartItem, removeCartItem } from "../../redux/action/cart.action";
import { getAllCourses } from "../../redux/action/course.action";
import MixMasterSeries from "../popovers/MixMasterSeries";

function Courses(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.items);

  const [episodeList, setEpisodeList] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const [allCourses, setAllCourses] = useState([]);
  const [allCourses2, setAllCourses2] = useState([]);
  useEffect(() => {
    props
      .getAllCourses()
      .then((res) => {
        setAllCourses2(res);
        let allCourseTitle = groupBy(res, (i) => i._id);
        let alCourdeArr = Object.keys(allCourseTitle).map((i) => {
          return {
            _id: i,
            ...allCourseTitle[i][0],
          };
        });
        setAllCourses(alCourdeArr);

        setEpisodeList(res.filter((c) => c._id === alCourdeArr[0]._id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCourseSelect = (id) => {
    setEpisodeList(allCourses2.filter((c) => c._id === id));
  };

  const handleAddCartItem = (item) => {
    let alreadyExist = Object.values(cart).filter(
      (i) => i.courseId == item._id && i.type == "bundle"
    );

    if (alreadyExist.length) {
      toast.info(
        `You already have a bundle (${item.courseName}) containing this chapter in your cart!`
      );
      return;
    }

    dispatch(addCartItem({ ...item, _id: item.id, courseId: item._id }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.seriesList}>
        <Box sx={{ height: { xs: "70vh", sm: "auto" } }} width="100%">
          <img
            src="/homeImage2.png"
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.topContent}
          sx={{ padding: { xs: "20px 30px", md: "50px 100px" } }}
        >
          <Box
            display="flex"
            flexDirection="column"
            className={classes.listSelection}
            width={{ xs: "100%", md: "70%" }}
            margin="auto"
          >
            <MixMasterSeries
              courses={allCourses}
              onSelectCourse={handleCourseSelect}
            >
              <Box display="flex">
                {episodeList.length ? (
                  <Typography variant="h4">
                    {episodeList[0].courseName}
                    <KeyboardArrowDown />
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h4" color="secondary">
                      All
                    </Typography>
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant="h4">
                      series&nbsp;
                      <KeyboardArrowDown />
                    </Typography>
                  </>
                )}
              </Box>
            </MixMasterSeries>
            <Box
              display="flex"
              flexDirection="column"
              maxHeight="90%"
              sx={{ margin: { xs: "10px 0", md: "30px 0 0" } }}
              overflow="auto"
            >
              <Divider />
              {console.log(episodeList, "allepi")}

              {episodeList.map((item, index, refArray) => (
                <Container key={"episode" + index + 1}>
                  <Box
                    display="flex"
                    margin="10px 0 10px"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    alignItems="center"
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      minHeight: 130,
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ width: { xs: "100%", sm: "55%" } }}
                    >
                      <Box display="flex" flexWrap="wrap" alignItems="center">
                        <Link to={`/edu/courses/${item._id}/${item.id}`}>
                          <Typography
                            align="left"
                            variant="h4"
                            style={{ textDecoration: "none", color: "#000" }}
                            sx={{ fontSize: { xs: "18px", sm: "34px" } }}
                          >
                            {/* "Mix-Master Series" Season 2 Episode 6: Deep & Tech
                          House Mixing SPECIAL GUEST: DJ CARNAGE aka GORDO */}
                            {item.name}
                          </Typography>
                        </Link>
                        <Box
                          display="flex"
                          width="100px"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box display="flex" height="26px">
                            {item.type == "bundle" && (
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                className={classes.labelText}
                              >
                                {item.type}
                              </Typography>
                            )}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ display: { xs: "flex", sm: "none" } }}
                          >
                            <Typography
                              variant="h1"
                              color="transparent"
                              className={classes.borderText}
                              sx={{ fontSize: { xs: "65px", sm: "150px" } }}
                            ></Typography>
                            <Typography variant="button">
                              {item.isPurchased && "Purchased"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <br />
                      {/* {item.coupons?.length > 0 && (
                        <>
                          {item.coupons?.map((coupon) => (
                            <Box
                              key={coupon._id}
                              className={classes.promoCodeBox}
                              alignItems="center"
                              sx={{
                                width: { xs: "100%", sm: "200px" },
                              }}
                            >
                              <IconPromoCode
                                style={{
                                  width: "20px",
                                  height: "auto",
                                }}
                              />
                              &nbsp;&nbsp;
                              <Typography variant="button">
                                -{coupon.amount}
                                {coupon.type == "percent" ? "%" : "$"}&nbsp;
                                promo code
                              </Typography>
                            </Box>
                          ))}
                        </>
                      )} */}
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="h3"
                        color="transparent"
                        className={classes.borderText}
                        sx={{ fontSize: { xs: "65px", sm: "52px" } }}
                      >
                        {"$ " + item.price}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      sx={{ display: { xs: "flex", sm: "none" } }}
                    >
                      <IconButton
                        color="secondary"
                        onClick={() =>
                          !cart[refArray[index].id]
                            ? handleAddCartItem(refArray[index])
                            : dispatch(removeCartItem(refArray[index].id))
                        }
                      >
                        {!cart[refArray[index].id] ? (
                          <AddCircle color="secondary" />
                        ) : (
                          <RemoveIcon color="secondary" />
                        )}
                      </IconButton>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      marginRight="-50px"
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    >
                      {item.isPurchased ? (
                        <CheckCircleIcon />
                      ) : (
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            !cart[refArray[index].id]
                              ? handleAddCartItem(refArray[index])
                              : dispatch(removeCartItem(refArray[index].id))
                          }
                        >
                          {!cart[refArray[index].id] ? (
                            <AddCircle color="secondary" />
                          ) : (
                            <RemoveIcon color="secondary" />
                          )}
                        </IconButton>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      sx={{ display: { sm: "flex" } }}
                    >
                      <Typography
                        variant="button"
                        align="center"
                        className={classes.verticalText}
                      >
                        {item.isPurchased ? "Purchased" : "Add to cart"}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider />
                </Container>
              ))}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    justifyContent: "center",
  },
  seriesList: {
    position: "relative",
    zIndex: 113,
    marginBottom: -7,
  },
  listSelection: {
    height: "100%",
  },
  topContent: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 112,
  },
  labelText: {
    backgroundColor: theme.palette.secondary.main,
    padding: "2px 14px",
  },
  verticalText: {
    transform: "rotate(-90deg)",
  },
  borderText: {
    "-webkit-text-stroke": "1px #1A1A30",
  },
  promoCodeBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
  },
}));

export default connect(null, { getAllCourses })(Courses);
