// import logo from "./logo.svg";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { login } from "../../redux/action/auth.action";
import PasswordField from "../PasswordField";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

function SignIn(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleLogin = (values) => {
    setLoading(true);
    props
      .login({
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location = "/";
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      height="100%"
    >
      <Box display="flex" className={classes.backButton}>
        <ButtonBase onClick={() => window.history.back()}>
          <ArrowBack />
          &nbsp; Back
        </ButtonBase>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
      >
        <Box display="flex" justifyContent="center" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            maxWidth="350px"
            paddingLeft="24px"
            paddingRight="24px"
          >
            <Typography align="center" variant="h3" color="initial">
              SIGN IN
            </Typography>
            <br />
            <form onSubmit={formik.handleSubmit}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" color="initial" gutterBottom>
                  Email address
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  placeholder="Ex.Lorem@gmail.com"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <br />
                <Typography variant="body1" color="initial">
                  Password
                </Typography>
                <PasswordField
                  fullWidth
                  id="password"
                  placeholder="Password"
                  variant="outlined"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <br />
                <Button variant="contained" color="primary" type="submit">
                  {loading && <CircularProgress color="secondary" />}
                  {!loading && (
                    <Typography variant="h6" color="textSecondary">
                      SIGN IN
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>

            <br />
            <Box display="flex" justifyContent="center">
              <Typography variant="body1">Not registered yet? </Typography>
              &nbsp;
              <ButtonBase onClick={props.signup}>
                <Typography
                  variant="body1"
                  color="button"
                  className={classes.textBtn}
                >
                  Create an Account
                </Typography>
              </ButtonBase>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1">Forgot password? </Typography>
              &nbsp;
              <ButtonBase onClick={props.forgotPass}>
                <Typography
                  variant="body1"
                  color="button"
                  className={classes.textBtn}
                >
                  Reset password
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  textBtn: {
    color: "#AAC9DD",
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "50px",
  },
}));

export default connect(null, { login })(SignIn);
