// import logo from "./logo.svg";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { forgotPasswordRequest } from "../../redux/action/auth.action";
import LineWaveGray from "../svgAssets/LineWaveGray";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

function ForgotPassword(props) {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);

  const forgotPass = (values) => {
    setLoading(true);
    props
      .forgotPasswordRequest({
        email: values.email,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setMailSent(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      forgotPass(values);
    },
  });

  const navigate = useNavigate();
  const [mailSent, setMailSent] = useState(false);

  return (
    <>
      {mailSent ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          className={classes.containerDiv}
        >
          <Box display="flex" justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              maxWidth="350px"
              paddingLeft="24px"
              paddingRight="24px"
            >
              <Typography align="center" variant="subtitle1" color="initial">
                <img src="/mailSent.png" />
              </Typography>
              <Typography align="center" variant="h3" color="initial">
                Check your email
              </Typography>
              <br />
              <Typography align="center" variant="subtitle1" color="initial">
                We have sent a password recover instructions to your email.
              </Typography>
            </Box>
          </Box>
          <div className={classes.bottomDiv}>
            <LineWaveGray
              style={{
                width: "100%",
                alignSelf: "center",
                color: "rgba(0,0,0,0.001)",
              }}
            />
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column" width="100%">
                <Typography align="center" variant="subtitle1" color="initial">
                  Did not receive the email? Check your spam filter,
                </Typography>
                <ButtonBase onClick={() => setMailSent(false)}>
                  <Typography align="center" variant="subtitle1" color="error">
                    try another email address
                  </Typography>
                </ButtonBase>

                <br />
              </Box>
            </Box>
          </div>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          height="100%"
        >
          <Box display="flex" className={classes.backButton}>
            <ButtonBase onClick={() => navigate("/")}>
              <ArrowBack />
              &nbsp; Back
            </ButtonBase>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Box display="flex" justifyContent="center" width="100%">
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                width="100%"
                maxWidth="350px"
                paddingLeft="24px"
                paddingRight="24px"
              >
                <Typography align="center" variant="h4" color="initial">
                  FORGOT PASSWORD
                </Typography>
                <br />
                <Typography variant="subtitle1" color="initial">
                  Enter your Email and we will send you there a link to reset
                  your password
                </Typography>

                <br />
                <form onSubmit={formik.handleSubmit}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" color="initial" gutterBottom>
                      Email address
                    </Typography>
                    <TextField
                      id="email"
                      placeholder="Email"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />

                    <br />
                    <Button variant="contained" color="primary" type="submit">
                      {loading && <CircularProgress color="secondary" />}
                      {!loading && (
                        <Typography variant="h6" color="textSecondary">
                          SEND
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </form>

                <br />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="0 30px"
                >
                  <ButtonBase onClick={props.signup}>
                    <Typography
                      variant="body1"
                      color="button"
                      className={classes.textBtn}
                    >
                      Create an Account
                    </Typography>
                  </ButtonBase>
                  <ButtonBase onClick={props.login}>
                    <Typography
                      variant="body1"
                      color="button"
                      className={classes.textBtn}
                    >
                      Sign In
                    </Typography>
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const useStyle = makeStyles((theme) => ({
  textBtn: {
    color: "#AAC9DD",
  },
  containerDiv: {
    position: "relative",
  },
  bottomDiv: {
    // marginTop: "200px",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "50px",
  },
}));

export default connect(null, { forgotPasswordRequest })(ForgotPassword);
