// import logo from "./logo.svg";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { useConfirmationDialogContext } from "../components/common/ConfirmationDialog";
import PasswordField from "../components/PasswordField";
import IconCircle from "../components/svgAssets/IconCircle";
import {
  deleteAccount,
  logout, updatePassword
} from "../redux/action/auth.action";
import { myOrder } from "../redux/action/order.action";

const validationSchema = yup.object({
  currentPassword: yup.string().required("Current Password is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm new password")
    .oneOf([yup.ref("newPassword")], "Passwords does not match"),
});

function Profile(props) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const dialogContext = useConfirmationDialogContext();
  const [loading, setLoading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("password");
  const [saveCard, setSaveCard] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [stateError, setStateError] = useState("");

  const [purchasedVideos, setPurchased] = useState([]);

  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    if (newValue == 0) {
      setSelectedMenu("password");
    } else {
      setSelectedMenu("purchaseHistory");
    }
  };

  const handleUpdatePassword = (values) => {
    setLoading(true);
    props
      .updatePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        toast.success(res.message);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleUpdatePassword(values);
    },
  });

  const handleDeleteUser = () => {
    dialogContext
      .showConfirmDialog("Are you sure ?", "Your account will be deleted !")
      .then((result) => {
        if (result) {
          //putsuccess action here
          console.log(result);
          let userData = JSON.parse(localStorage.getItem("userData"));
          props
            .deleteAccount(userData._id)
            .then((res) => {
              toast.success(res.message);
              localStorage.removeItem("token");
              localStorage.removeItem("userData");
              navigate("/auth");
              window.location.reload();
              logout();
            })
            .catch((err) => {
              toast.error(err.response.data.message);
            });
        } else {
        }
      });
  };

  useEffect(() => {
    dispatch(myOrder()).then((data) => {
      setPurchased(data);
    });
  }, []);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Studio DMI | Profile</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Container maxWidth="md">
        <br />
        <Grid container spacing={1} justifyContent="space-around">
          <Grid item sm={4} xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h4" color="initial">
                Account settings
              </Typography>
              <br />
              <Tabs
                value={selectedMenu == "password" ? 0 : 1}
                onChange={handleTabChange}
                variant="standard"
                indicatorColor="secondary"
                textColor="secondary"
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                <Tab
                  label="Password"
                  color="#E2211C"
                  style={{
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Purchase history"
                  style={{
                    textTransform: "none",
                  }}
                />
              </Tabs>
              <Grid
                container
                spacing={1}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <Grid item xs={4} sm={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    onClick={() => setSelectedMenu("password")}
                    className={classes.menuBtn}
                    color={selectedMenu == "password" ? "error" : "textPrimary"}
                  >
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    onClick={() => setSelectedMenu("purchaseHistory")}
                    className={classes.menuBtn}
                    color={
                      selectedMenu == "purchaseHistory"
                        ? "error"
                        : "textPrimary"
                    }
                  >
                    Purchase history
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box
            display="flex"
            width="50px"
            justifyContent="center"
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <Divider
              variant="fullWidth"
              orientation="vertical"
              className={classes.verticalDivider}
            />
          </Box>
          <Grid item xs={12} sm={7} md={8}>
            {selectedMenu == "password" && (
              <form onSubmit={formik.handleSubmit}>
                <Box display="flex" flexDirection="column" width="100%">
                  <br />
                  <Typography variant="h5" color="initial">
                    ACCOUNT SETTINGS
                  </Typography>

                  <br />

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1" color="initial">
                          Current password
                        </Typography>
                        <PasswordField
                          fullWidth
                          id="currentPassword"
                          name="currentPassword"
                          placeholder="Current Password"
                          variant="outlined"
                          value={formik.values.currentPassword}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.currentPassword &&
                            Boolean(formik.errors.currentPassword)
                          }
                          helperText={
                            formik.touched.currentPassword &&
                            formik.errors.currentPassword
                          }
                        />
                        <br />
                        <Typography variant="body1" color="initial">
                          New password
                        </Typography>
                        <PasswordField
                          fullWidth
                          id="newPassword"
                          placeholder="New Password"
                          variant="outlined"
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.newPassword &&
                            Boolean(formik.errors.newPassword)
                          }
                          helperText={
                            formik.touched.newPassword &&
                            formik.errors.newPassword
                          }
                        />
                        <br />
                        <Typography variant="body1" color="initial">
                          Confirm password
                        </Typography>
                        <PasswordField
                          fullWidth
                          id="confirmPassword"
                          placeholder="Password"
                          variant="outlined"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.confirmPassword &&
                            Boolean(formik.errors.confirmPassword)
                          }
                          helperText={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <br />
                  <Box display="flex" flexWrap="wrap">
                    <Typography variant="body1">
                      Can’t remember your current password?{" "}
                    </Typography>
                    &nbsp;
                    <ButtonBase onClick={() => navigate("/forgotPassword")}>
                      <Typography
                        variant="body1"
                        color="button"
                        className={classes.textBtn}
                      >
                        Reset your password
                      </Typography>
                    </ButtonBase>
                  </Box>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        <Typography variant="h6" color="textSecondary">
                          {loading && <CircularProgress color="secondary" />}
                          {!loading && (
                            <Typography variant="h6" color="textSecondary">
                              SAVE PASSWORD
                            </Typography>
                          )}
                        </Typography>
                      </Button>
                      <br />
                      <br />
                      <br />
                      <Typography variant="h5" color="initial">
                        DELETE ACCOUNT
                      </Typography>
                      <Typography variant="body1">
                        Would you like to delete account?
                      </Typography>

                      <br />
                      <ButtonBase onClick={() => handleDeleteUser()}>
                        <Typography
                          variant="body1"
                          color="button"
                          className={classes.textBtn}
                          style={{ textDecoration: "underline" }}
                        >
                          I want to delete my account
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>

                  <br />
                  <br />
                </Box>
              </form>
            )}
            {selectedMenu == "purchaseHistory" && (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                // sx={{ height: { xs: "auto", md: "65%" } }}
                // overflow="auto"
              >
                <br />
                <Typography variant="h5" color="initial">
                  YOUR HISTORY VIDEOS
                </Typography>

                <br />

                {purchasedVideos.length == 0
                  ? "You have not purchased any course in past"
                  : ""}
                {purchasedVideos.map((item, index) => (
                  <>
                    <Grid
                      container
                      spacing={2}
                      alignContent="center"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Grid item md={6} xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box
                            display="flex"
                            position="relative"
                            paddingTop="5px"
                          >
                            <IconCircle
                              style={{
                                color: "rgba(0,0,0,0.001)",
                              }}
                            />
                            <Box
                              display="flex"
                              className={classes.historyIndex}
                              justifyContent="center"
                            >
                              <Typography variant="body1" color="secondary">
                                {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography variant="body1" color="initial">
                            {item?.items?.map((i) => i.name).join(", ")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={3} xs={5}>
                        <Typography
                          align="center"
                          variant="body1"
                          color="initial"
                          className={classes.dateBox}
                        >
                          {moment(item.createdAt).format("lll")}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={4}>
                        <Typography variant="h4" color="initial">
                          ${item.total}
                        </Typography>
                      </Grid>
                    </Grid>
                    <br />
                    <Divider className={classes.greyDivider} />
                    <br />
                  </>
                ))}
                <br />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    marginTop: 100,
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  menuBtn: {
    cursor: "pointer",
  },
  verticalDivider: {
    height: "auto",
    width: "2px",
    color: "#dbdbdb",
  },
  greyDivider: {
    color: "#dbdbdb",
  },
  textBtn: {
    color: theme.palette.secondary.main,
  },
  historyIndex: {
    width: "100%",
    position: "absolute",
    top: 5,
    left: 0,
  },
  dateBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
    padding: "5px 0",
  },
}));

export default connect(null, { updatePassword, deleteAccount })(Profile);
