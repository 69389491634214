import createTheme from "@mui/material/styles/createTheme";
import BarlowCondensedRegular from "./../fonts/BarlowCondensed-Regular.woff2";
import NunitoSansRegular from "./../fonts/NunitoSans-Regular.woff2";

// Create a theme instance.

const BarlowCondensed_Regular = {
  fontFamily: "BarlowCondensed",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "500",
  src: `local('BarlowCondensed'),
   local('BarlowCondensed-Regular'),
    url(/${BarlowCondensedRegular}) format('woff2')`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const NunitoSans_Regular = {
  fontFamily: "NunitoSans",
  fontStyle: "regular",
  fontDisplay: "swap",
  fontWeight: "400",
  src: `
    local('NunitoSans'),
    local('NunitoSans-Regular'),
    url(${NunitoSansRegular}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 1024,
      lg: 1280,
      xl: 1580,
    },
  },
  palette: {
    primary: {
      main: "#1A1A30",
      light: "#EFEBFE",
      contrastText: "#fff",
      dark: "#111111",
    },
    secondary: {
      main: "#E2211C",
      light: "#D8E6EF",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#1A1A30",
      secondary: "#fff",
    },
    background: {
      main: "#212529",
      paper: "#fff",
    },
    divider: {
      background: {
        main: "#212529",
        secondary: "#D8E6EF",
      },
      light: "#D8E6EF",
    },
  },

  typography: {
    fontFamily: "BarlowCondensed",
    h6: {
      textTransform: "capitalize",
    },
    h3: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
    // subtitle1: {
    //   "&:first-letter": {
    //     textTransform: "capitalize",
    //   },
    // },
    // body1: {
    //   "&:first-letter": {
    //     textTransform: "capitalize",
    //   },
    // },
    button: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 19,
      colorTextPrimary: {
        color: "#AAC9DD",
      },
      colorTextSecondary: {
        color: "#6D6A77",
      },
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BarlowCondensed';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('BarlowCondensed'), local('BarlowCondensed-Regular'), url(${BarlowCondensedRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontStyle: "normal",
          fontWeight: 400,
          textTransform: "none",
        },
        button: {
          fontStyle: "normal",
          fontWeight: 400,
          textTransform: "uppercase",
          fontSize: 18,
        },
        h1: {
          textTransform: "uppercase",
          fontWeight: 700,
          fontSize: "150px",
          colorTextPrimary: {
            color: "#1A1A30",
          },
          colorTextSecondary: {
            color: "#000000",
          },
        },
        h4: {
          textTransform: "uppercase",
          fontWeight: 600,
        },
        h3: {
          textTransform: "uppercase",
          fontWeight: 600,
        },
        subtitle2: {
          textTransform: "uppercase",
          fontWeight: 400,
        },
        colorTextPrimary: {
          color: "#6D6A77",
        },
        colorTextSecondary: {
          color: "#fff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: 10,
          fontSize: 18,
          textTransform: "capitalize",
          letterSpacing: "0.01em",
        },
        contained: {
          boxShadow: "none",
          color: "#fff",
        },
        // outlined: {
        //   color: "#fff",
        //   border: "1px solid #fff",
        //   "&:hover": {
        //     border: "1px solid #fff",
        //   },
        // },
        text: {
          padding: 0,
          width: "auto",
        },

        "&.Mui-disabled": {
          background: "grey",
          backgroundColor: "grey",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "1px solid #A5B0BE",
          borderRadius: 0,
          boxSizing: "border-box",
          height: 49,
          fontSize: 16,
          fontWeight: 400,
          overflow: "hidden",
          alignItems: "center",
          transition: "border 0.3s ease",
        },
        underline: {
          "&:after": {
            height: "calc(100% + 1px)",
            borderRadius: 0,
            bottom: -1,
          },
          "&:before": {
            display: "none",
          },
        },
        input: {
          fontSize: 16,
          fontWeight: 400,
          borderRadius: 0,
        },
        multiline: {
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 12,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingLeft: "4px",
          borderRadius: 0,
          boxSizing: "border-box",
          height: 49,
          overflow: "hidden",
          fontSize: 16,
          fontWeight: 400,
          alignItems: "center",
          transition: "border 0.3s ease",
        },
        multiline: {
          minHeight: 49,
          height: "auto",
        },
      },
    },
  },
  overrides: {
    // MuiCssBaseline: {
    //   "@global": {
    //     "@font-face": [BarlowCondensed_Regular, NunitoSans_Regular],
    //   },
    // },
    MuiPaper: {
      root: {
        backgroundColor: "#fff",
      },
      rounded: {
        borderRadius: 8,
      },
      elevation1: {
        boxShadow:
          "0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
      },
      elevation4: {
        boxShadow:
          "0px 2px 4px -1px rgba(142, 142, 142, 0.2), 0px 4px 5px 0px rgba(243, 243, 243, 0.14), 0px 1px 10px 0px rgba(204, 204, 204, 0.12)",
      },
    },
    // MuiTypography: {
    //   button: {
    //     fontStyle: "normal",
    //     fontWeight: 400,
    //     textTransform: "capitalize",
    //     fontSize: 28,
    //     color: "#AAC9DD",
    //   },
    //   colorTextPrimary: {
    //     color: "#6D6A77",
    //   },
    //   colorTextSecondary: {
    //     color: "#fff",
    //   },
    // },
    // MuiOutlinedInput: {
    //   root: {
    //     border: "1px solid #1A1A30",
    //     borderRadius: 0,
    //     paddingLeft: 8,
    //     boxSizing: "border-box",
    //     filter: "drop-shadow(0px 4px 10px #FAF3F3)",
    //     height: 49,
    //     overflow: "hidden",
    //     fontSize: "14px",
    //     alignItems: "center",
    //     transition: "border 0.3s ease",
    //   },
    //   multiline: {
    //     minHeight: 49,
    //     height: "auto",
    //   },
    // },
    // MuiInput: {
    //   root: {
    //     border: "1px solid #E8E7E9",
    //     backgroundColor: "#F5F5F6",
    //     borderRadius: 25,
    //     filter: "drop-shadow(0px 4px 10px #FAF3F3)",
    //     boxSizing: "border-box",
    //     height: 40,
    //     overflow: "hidden",
    //     alignItems: "center",
    //     transition: "border 0.3s ease",
    //   },
    //   underline: {
    //     "&:after": {
    //       height: "calc(100% + 1px)",
    //       borderRadius: 8,
    //       bottom: -1,
    //     },
    //     "&:before": {
    //       display: "none",
    //     },
    //   },
    //   input: {
    //     padding: 10,
    //     fontSize: 14,
    //     borderRadius: 8,
    //   },
    //   multiline: {
    //     paddingLeft: 10,
    //     paddingRight: 10,
    //     paddingTop: 12,
    //   },
    // },
    // MuiButton: {
    //   contained: {
    //     backgroundColor: "#fff",
    //     boxShadow: "none",
    //     color: "#1A1A30",
    //   },
    //   disabled: {
    //     background: "grey",
    //     backgroundColor: "grey",
    //   },

    //   root: {
    //     borderRadius: 10,
    //     padding: 8,
    //     paddingLeft: 24,
    //     paddingRight: 24,
    //     fontSize: 16,
    //     textTransform: "ca",
    //     letterSpacing: "0.01em",
    //   },
    //   sizeSmall: {
    //     padding: "7px 12px",
    //   },
    // },
    MuiContainer: {
      root: {
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        borderRadius: 12,
        "@media (min-width: 1600px)": {
          padding: 0,
          paddingLeft: 32,
          paddingRight: 32,
          marginTop: 16,
        },
        "@media (min-width: 600px)": {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      maxWidthLg: {
        marginTop: 0,
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
      maxWidthXl: {
        marginTop: 0,
        paddingLeft: 0,
      },
    },
    MuiInputLabel: {
      formControl: {
        top: 12,
        left: 10,
        transform: "translate(0, 22px) scale(1)",
      },
      shrink: {
        transform: "translate(0, 13px) scale(0.7)",
        zIndex: 1,
      },
      filled: {
        transform: "translate(2px, 6px) scale(1)",
        "&$shrink": {
          transform: "translate(0px, -6px) scale(0.75)",
        },
      },
      outlined: {
        transform: "translate(2px, 6px) scale(1)",
        "&$shrink": {
          transform: "translate(4px, -16px) scale(0.75)",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormHelperText: {
      root: {
        paddingLeft: 5,
      },
    },
    MuiSelect: {
      root: {
        borderRadius: 8,
      },
      icon: {
        top: "calc(50% - 10px)",
        right: 0,
      },
      selectMenu: {
        paddingRight: "24px",
      },
    },
    MuiFormControl: {
      root: {
        "& label + div": {
          alignItems: "flex-end",
          '&[role="radiogroup"]': {
            alignItems: "flex-start",
          },
          paddingBottom: 4,
          "& input, > div, > select": {
            padding: "24px 8px 0",
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        alignItems: "flex-end",
        paddingLeft: 0,
        paddingRight: 0,
        height: "auto",
        "& button": {
          width: 32,
          height: 32,
          padding: 0,
        },
        "& p": {
          minWidth: 24,
          lineHeight: "16px",
        },
        "& > svg": {
          position: "relative",
          top: 3,
        },
      },
      positionStart: {
        marginRight: 0,
        paddingRight: "0 !important",
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiToolbar: {
      root: {
        borderRadius: 8,
      },
    },

    MuiListItemText: {
      root: {
        whiteSpace: "nowrap",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 16,
      },
      bar: {
        borderRadius: 16,
      },
    },
    MuiTablePagination: {
      input: {
        marginRight: 32,
        marginLeft: 8,
      },
      selectRoot: {
        marginLeft: 0,
        marginRight: 0,
      },
      select: {
        paddingRight: 24,
      },
      selectIcon: {
        top: 4,
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "16px !important",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        borderRadius: 0,
        boxShadow: "inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)",
      },
    },

    MuiExpansionPanel: {
      root: {
        "&:first-child": {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
        "&:last-child": {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        },
        "&$expanded": {
          borderRadius: 8,

          "& + div": {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        },
      },
    },
    MuiDialogTitle: {
      root: {
        position: "relative",
        marginBottom: 32,
        "&:after": {
          content: '""',
          position: "absolute",
          width: 60,
          height: 4,

          bottom: 0,
          left: 26,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        "@media (min-width: 960px)": {
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiTabs: {
      root: {
        borderRadius: 10,
      },
      indicator: {
        borderRadius: "10px 10px 0 0",
        height: 4,
      },
    },

    MUIDataTableToolbar: {
      filterPaper: {
        maxWidth: "none",
      },
    },

    // MUIDataTable: {
    //   responsiveStacked: {
    //     overflow: "auto !important",
    //     overflowX: "auto !important",
    //   },
    //   responsive: "scroll",
    //   tableRoot: {
    //     minWidth: 360,
    //   },
    // },

    MUIDataTable: {
      root: {
        backgroundColor: "rgba(0,0,0,0)",
        overflow: "hidden",
        borderRadius: 15,
        border: "1px solid rgba(2, 23, 34, 0.1)",
      },
      paper: {
        boxShadow: "none",
        backgroundColor: "rgba(0,0,0,0)",
        border: "1px solid rgba(2, 23, 34, 0.1)",
      },
    },
    MUIDataTableBody: {
      root: {
        borderBottom: "1px solid rgba(2, 23, 34, 0.1)",
      },
    },

    MuiTableFooter: {
      root: {
        borderRadius: 15,

        borderTop: "1px solid rgba(2, 23, 34, 0.1)",
      },
    },

    MUIDataTablePagination: {
      toolbar: {
        padding: 0,
        "& > p:nth-child(2)": {
          "@media (max-width: 400px)": {
            display: "none",
          },
        },
      },
      root: {
        padding: 0,
      },
    },
  },
});
