import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { logout } from "../../redux/action/auth.action";
import IconHelp from "../svgAssets/IconHelp";
import IconSetting from "../svgAssets/IconSetting";
import IconSignout from "../svgAssets/IconSignout";
import IconVideo from "../svgAssets/IconVideo";

export default function ProfileDrawer(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {};
  const handleSignout = () => {
    logout();

    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
    window.location.reload();
  };

  const getUserFirstName = useMemo(() => {
    if (!userData) return "";
    else if (userData.name) return userData.name.split(" ")[0];
    else return "";
  }, [userData.name]);

  return (
    <div>
      <Box
        width="350px"
        role="profileMenu"
        onClick={props.toggle(false)}
        onKeyDown={props.toggle(false)}
        className={classes.profileDrawer}
      >
        <List>
          <ListItem>
            <Box display="flex" width="100%" alignItems="center">
              <Typography
                variant="h5"
                color="initial"
                style={{ textTransform: "uppercase" }}
              >
                YOUR PROFILE {getUserFirstName}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Accordion className={classes.menuItem}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRight />}
                onClick={() => navigate("/my-videos")}
              >
                <Box display="flex" alignItems="center">
                  {" "}
                  <IconVideo />
                  &nbsp;&nbsp; &nbsp;
                  <Typography variant="h6" color="initial">
                    My video course
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>lorem ipsum!</AccordionDetails>
            </Accordion>
          </ListItem>
          {/* <Divider variant="middle" className={classes.greyDivider} /> */}
          <ListItem>
            <Box display="flex" flexDirection="column" width="100%">
              <Accordion className={classes.menuItem}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowRight />}
                  onClick={() => navigate("/profile")}
                >
                  <Box display="flex" alignItems="center">
                    {" "}
                    <IconSetting />
                    &nbsp;&nbsp; &nbsp;
                    <Typography variant="h6" color="initial">
                      Account settings
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>lorem ipsum!</AccordionDetails>
              </Accordion>

              <Accordion className={classes.menuItem}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowRight />}
                  onClick={() => navigate("/contactus")}
                >
                  <Box display="flex" alignItems="center">
                    {" "}
                    <IconHelp />
                    &nbsp;&nbsp; &nbsp;
                    <Typography variant="h6" color="initial">
                      Help
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>lorem ipsum!</AccordionDetails>
              </Accordion>
            </Box>
          </ListItem>

          {/* <Divider variant="middle" className={classes.greyDivider} /> */}
          <ListItem>
            <Box display="flex" alignItems="center" className={classes.signout}>
              <ButtonBase onClick={() => handleSignout()}>
                {" "}
                <IconSignout />
                &nbsp;&nbsp; &nbsp;
                <Typography variant="h6" color="initial">
                  Sign Out
                </Typography>
              </ButtonBase>{" "}
            </Box>
          </ListItem>
        </List>
      </Box>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  profileDrawer: {
    padding: "30px",
  },
  menuItem: {
    width: "100%",
  },
  greyDivider: {
    color: "#dbdbdb",
  },
  signout: {
    paddingLeft: "16px",
  },
}));
