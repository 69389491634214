import { Box, Button, Grid, Rating, TextField, Typography } from "@mui/material";
import { toast } from "material-react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Lightbox from "../../components/lightbox/lightbox";
import { addCartItem } from "../../redux/action/cart.action";
import { showProduct } from "../../redux/action/product.action";
import { makeStyles } from "@mui/styles";
import { FetchTours } from "../../redux/action/course.action";

export default function TourWarsaw(props) {
    const classes = useStyle();
    const dispatch = useDispatch();
    const [tourInfo, setTourInfo] = useState({})
    const cart = useSelector((state) => state.cart.items);
    console.log({ cart })
    const { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0);
        FetchTours()
            .then((data) => {
                if (data && data.length)
                    setTourInfo(data.find((tour) => tour._id === "20243"))
            })
            .catch((err) => {
                toast.error("Tour not found!")
            })
    }, [])

    const handleAddCartItem = (item) => {
        if (tourInfo && tourInfo.purchaseLimit && tourInfo.purchaseLimit <= tourInfo.purchases) {
            toast.error("Tickets are sold out!")
            return;
        }

        let alreadyExist = Object.values(cart).filter(
            (i) => i.courseId == item._id && ["bundle", "product", "tour"].includes(i.type)
        );

        if (alreadyExist.length) {
            toast.info(
                `You already have a bundle (${item.courseName}) containing this chapter in your cart!`
            );
            return;
        }

        dispatch(addCartItem({ ...item, _id: item._id, courseId: item._id, type: "tour" }));
    };




    return (
        <>
            <div style={{ padding: 12 }}>
                <div
                    className={classes.homeImageDiv}
                    style={{
                        color: "#fff",
                        textAlign: "left",
                        background: "#212121",
                    }}
                    sx={{ height: { xs: "100vh", sm: "auto" } }}
                >
                    <Box
                        sx={{
                            height: "auto",
                            padding: { xs: "16px", sm: "100px" },
                        }}
                        width="100%"
                    >
                        <Box
                            display={"flex"}
                            flexDirection="column"
                            gap={"64px"}
                            width={"100%"}
                            height="100%"
                        >
                            <Box
                                display={"flex"}
                                flexDirection="column"
                                width={"100%"}
                                alignItems="center"
                                sx={{
                                    textAlign: { xs: "center", sm: "left" },
                                    gap: { xs: 0, sm: "16px" },
                                }}
                            >
                                <Box fontSize="48px" fontFamily={'"Smooch", cursive'} whiteSpace={"nowrap"} lineHeight="1">
                                    Luca Pretolesi
                                </Box>
                                <Box
                                    fontSize="32px"
                                    style={{
                                        backdropFilter: "blur(1px)",
                                    }}
                                >
                                    Mixing and Mastering Workshop Tour 2024
                                </Box>

                                <Box
                                    sx={{ fontSize: "40px", fontWeight: 500, color: "#FF3131" }}
                                >
                                    EVENT DETAILS
                                </Box>
                                <br />
                                <br />
                                <Box
                                    display={"flex"}
                                    flexWrap="wrap"
                                    justifyContent={"center"}
                                    width="100%"
                                    gap="96px"
                                    columnGap={"192px"}
                                    textAlign={"left"}
                                    sx={{
                                        flexDirection: { xs: "column" },
                                        alignItems: { xs: "center" },
                                        paddingBottom: { xs: "48px", sm: "24px" },
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection="column"
                                        gap="16px"
                                    // maxWidth={"425px"}
                                    >
                                        <Box
                                            display={"flex"}
                                            justifyContent="space-between"
                                            gap="16px"
                                        >
                                            <div>
                                                <Typography variant="h6" marginBottom={0}>
                                                    CLOSER MUSIC
                                                </Typography>
                                                <Typography variant="body1">
                                                    Puławska 366, 02-819 Warszawa, Poland
                                                </Typography>
                                            </div>
                                            <Typography variant="h6" marginBottom={0}>
                                                WARSAW, POLAND
                                            </Typography>
                                        </Box>
                                        <Typography variant="h6" marginBottom={0}>
                                            FRIDAY MARCH 22 2024 3:00p-6:00p
                                        </Typography>
                                        {/* <h5 style={{ marginBottom: 0, lineHeight: 1 }}>
                                            FRIDAY MARCH 22 2024 3:00p-6:00p
                                        </h5> */}
                                        <h5 style={{ opacity: 0, visiblity: 0 }}>a</h5>
                                        <Box
                                            display={"flex"}
                                            flexDirection="column"
                                            alignItems={"center"}
                                            textAlign={"center"}
                                        >
                                            <Typography variant="h6" marginBottom={4}>
                                                TICKET PRICE - $199
                                            </Typography>
                                            {/* <h4>TICKET PRICE - $199</h4> */}
                                            <button className={classes.workshopBuyBtn}
                                                onClick={() => handleAddCartItem({ _id: "20243", courseId: "20243", type: "tour", tourId: "20243", courseName: "Warsaw tour", name: "Warsaw tour", price: 199 })}
                                            >
                                                CLICK TO BUY <br />
                                                MIAMI WORKSHOP
                                            </button>

                                            <Typography variant="caption" fontSize={16} marginBottom={4}>
                                                Tickets are limited and final sale please contact
                                                tommy@studiodmi.com with questions
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box
                                        display={"flex"}
                                        flexDirection="column"
                                        sx={{ width: "425px", display: { xs: "none", sm: "flex" } }}
                                    ></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </div >
        </>
    )
}


const useStyle = makeStyles((theme) => ({
    home: {
        textAlign: "center",
        justifyContent: "center",
    },
    homeImageDiv: {
        position: "relative",
        width: "100%",
        zIndex: 112,
        marginBottom: -7,
    },
    seriesList: {
        position: "relative",
        zIndex: 113,
        marginBottom: -7,
    },
    listSelection: {
        height: "100%",
    },
    topContent: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 112,
    },
    bottomContent: {
        position: "absolute",
        bottom: 60,
        left: 0,
        padding: "0 100px",
        zIndex: 113,
    },

    onlineMentoring: {
        backgroundColor: theme.palette.background.main,
    },
    userForm: {
        backgroundColor: theme.palette.secondary.main,
    },
    sideContent: {
        position: "absolute",
        height: "99.5%",
        top: 0,
        right: 0,
        background: `linear-gradient(90deg, rgba(0,0,0,0) 0%,rgba(0,0,0,1)  93.76%)`,
        paddingLeft: 600,
        paddingRight: 100,
        zIndex: 112,
        // backdropFilter: "blur(6px)",
    },
    rotatedButton: {
        backdropFilter: "inherit",
    },
    headingText: {
        fontWeight: 600,
    },
    labelText: {
        backgroundColor: theme.palette.secondary.main,
        padding: "2px 14px",
    },
    verticalText: {
        transform: "rotate(-90deg)",
    },
    borderText: {
        "-webkit-text-stroke": "1px #1A1A30",
    },
    promoCodeBox: {
        backgroundColor: "#AAC9DD",
        borderRadius: "44px",
    },
    customAddIcon: {
        border: "1px solid #fff",
        borderRadius: "25px",
        color: "#fff",
    },
    customDivider: {
        height: "2px",
        background: theme.palette.secondary.main,
        width: "50%",
    },
    testimonialCard: {
        borderRadius: "5px",
        width: "100%",
    },
    tour_ul: {
        marginInline: "auto",
        zIndex: 114,
        "@media (max-width: 460px)": {
            marginInline: "unset",
        },
    },
    tour_list: {
        display: "flex",
        gap: "24px",
        alignItems: "flex-start",
    },
    tour_city: {
        width: "130px",
        color: "#FF3131",
        fontWeight: 600,
        textShadow: "1px 1px 1px #000",
        "@media (max-width: 576px)": {
            width: "102px",
        },
    },
    tour_venue: {
        width: "108px",
        textShadow: "1px 1px 2px #000",
        "@media (max-width: 576px)": {
            width: "84px",
        },
    },
    tour_date: {
        width: "92px",
        color: "#FF3131",
        fontWeight: 600,
        textShadow: "1px 1px 1px #000",
        "@media (max-width: 576px)": {
            width: "72px",
        },
    },
    tour_btn: {
        width: "108px",
        cursor: "pointer",
        background: "transparent",
        border: "1px solid #FFF8F8",
        boxShadow: "0px 0px 5px 1px #ff3131,inset 0px 0px 5px 1px #ff3131",
        color: "#fff",
        textShadow: "1px 1px 2px #313131",
        fontWeight: 500,
        borderRadius: "2px",
        "@media (max-width: 576px)": {
            width: "84px",
            // display: "none",
        },
    },
    workshopBuyBtn: {
        padding: "16px 12px",
        width: "256px",
        cursor: "pointer",
        background: "transparent",
        color: "#fff",
        border: "1px solid #FFF8F8",
        boxShadow: "0px 0px 10px 2px #ff3131,inset 0px 0px 10px 2px #ff3131",
        borderRadius: "4px",
        position: "relative",
        marginBottom: "24px",
        "&:hover": {
            boxShadow: "1px 0px 10px 2px #ff3131,inset 1px 1px 10px 2px #ff3131",
        },
    },
    redoutlinedBtn: {
        color: "#fff",
        border: "1px solid #fff",
        "&:hover": {
            border: "1px solid #fff",
        },
    },
}));