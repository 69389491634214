// import logo from "./logo.svg";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { useRef, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import Success from "../components/common/SuccesMessage";
import Paypal from "../components/payments/Paypal";
import Square from "../components/payments/Square";
import { emptyCart } from "../redux/action/cart.action";
import { applyCoupon } from "../redux/action/order.action";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  cardNo: yup
    .string()
    .required("Card Number is required")
    .length(16, "Enter a valid card no"),
  cardExpiry: yup.string().required("Card Expiry is required"),
  cvc: yup.number().required("CVC is required"),
  nameOnCard: yup.string().required("Name is required"),
  streetAddress: yup.string().required("Street Address is required"),
  zipCode: yup.string().required("Zip Code is required"),
});

const CustomRadio = withStyles({
  root: {},
  checked: {},
})((props) => (
  <Checkbox
    icon={<RadioButtonUnchecked color="disabled" />}
    checkedIcon={<RadioButtonChecked />}
    color="primary"
    {...props}
  />
));

function Payment() {
  const classes = useStyle();

  const navigate = useNavigate();
  const [addPromo, setAddPromo] = useState(false);
  const dispatch = useDispatch();
  const [saveCard, setSaveCard] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [coupon, setCoupon] = useState('')
  const [couponSuccess, setCouponSuccess] = useState(false)
  const [couponMessage, setCouponMessage] = useState('')
  const [discount, setDiscount] = useState(0)
  const userName = useSelector((state) => state.auth.name);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const couponRef = useRef()
  console.log(userData);
  console.log("Coupon", coupon)
  const [allStates, setAllStates] = useState([
    {
      name: "Washington",
    },
    {
      name: "California",
    },
  ]);
  const [allCountries, setAllCountries] = useState([
    {
      name: "Los santos",
    },
    {
      name: "Vegas",
    },
  ]);

  const handleSaveCard = (event) => {
    setSaveCard(event.target.checked);
  };
  const cart = useSelector((state) => state.cart.items);

  const handleApplyCoupon = (e) => {
    let items = Object.values(cart)
    dispatch(applyCoupon(items, coupon))
      .then(res => {
        console.log(res)
        setCouponSuccess(true)
        setDiscount(res.discount)
        setCouponMessage(res.message)
      })
      .catch(err => {
        setCouponSuccess(false)
        if (err.response)
          setCouponMessage(err.response?.data.message)
      })
  }

  const handleStateSelect = (event, value) => {
    console.log(event, value);
    // setFormAddress(value);
    setSelectedState(event.target.value);
    if (value?.name?.length) {
      setStateError("");
    }
    console.log(selectedState);
  };
  const handleCountrySelect = (event, value) => {
    console.log(event, value);
    // setFormAddress(value);
    setSelectedCountry(event.target.value);
    if (value?.name?.length) {
      setCountryError("");
    }
    console.log(selectedState);
  };
  const [paymentSucces, setPaymentSucces] = useState(false);
  const handlePaySuccess = () => {
    setPaymentSucces(true);
    setPaymentStatus("success");
    dispatch(emptyCart());
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      cardNo: "",
      cardExpiry: "",
      cvc: "",
      nameOnCard: "",
      streetAddress: "",
      zipCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  const total = useMemo(() => {
    if (Object.values(cart).length == 0)
      return 0;
    else {
      let sum = 0
      Object.values(cart).map((i) => {
        sum = sum + i.price;
      });
      let total1 = sum
      if (discount > 0)
        total1 = sum - discount
      return total1
    }
  }, [discount]);
  if (Object.values(cart).length == 0 && !paymentStatus) {
    navigate("/edu");
  }

  return (
    <div className={classes.edu}>
      <Helmet>
        <title>Studio DMI </title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Container maxWidth="sm">
        {paymentStatus == "success" ? (
          <Success />
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%", marginTop: 32 }}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Place order</Typography>
            </Grid>
            {Object.values(cart).map((i, index) => {
              return (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="title">
                      {index + 1}. {i.name}
                    </Typography>{" "}
                    <Typography variant="title" align="right">
                      <b> $ {i.price}</b>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <Divider />
            </Grid>
            {
              discount > 0 &&
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">
                  </Typography>
                  <Typography variant="h5">
                    <b> Discount $ {discount}</b>
                  </Typography>
                </Box>
              </Grid>
            }
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">
                  {Object.values(cart).length} Items
                </Typography>
                <Typography variant="h5">
                  <b> Total $ {total}</b>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                className={classes.promoCode}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" color="initial">
                    Promo code
                  </Typography>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => setAddPromo(!addPromo)}
                  >
                    <Typography variant="h6" color="error">
                      {addPromo ? "Close" : "Add"}
                    </Typography>
                  </Button>
                </Box>
                {addPromo && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    paddingBottom="4px"
                  >
                    <br />
                    <TextField
                      id="promoCode"
                      placeholder="Code"
                      variant="outlined"
                      error={!couponSuccess && couponMessage !==''}
                      ref={couponRef}
                      onChange={(e) => { setCoupon(e.target.value) }}
                    />
                    {
                      couponSuccess ?
                        <Typography variant="caption" color="green">{couponMessage}</Typography>
                        :
                        <Typography variant="caption" color="red">{couponMessage}</Typography>
                    }
                    <br />
                    <Button variant="contained" color="primary" onClick={() => handleApplyCoupon()}>
                      <Typography variant="h6" color="textSecondary">
                        Apply
                      </Typography>
                    </Button>
                    <br />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                marginTop="24px"
              >
                <Paypal
                  items={Object.values(cart)}
                  onSuccess={() => handlePaySuccess()}
                  coupon={coupon}
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Divider
                    variant="fullWidth"
                    style={{ height: "1px", width: "45%" }}
                  />
                  <Typography variant="body1" color="initial">
                    or
                  </Typography>
                  <Divider
                    variant="fullWidth"
                    style={{ height: "1px", width: "45%" }}
                  />
                </Box>

                <br />
                <Square
                  items={Object.values(cart)}
                  total={total}
                  onSuccess={() => handlePaySuccess()}
                  userName={userData.name}
                />
                {/* <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // onClick={() => handlePayClick()}
                >
                  <Typography variant="h6" color="textSecondary">
                    Pay via square
                  </Typography>
                </Button> */}

                <br />
                <br />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  edu: {
    backgroundColor: "#fff",
    marginTop: 100,
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
}));

export default Payment;
