// import logo from "./logo.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router";
import { useConfirmationDialogContext } from "../components/common/ConfirmationDialog";

const PluginBox = (props) => {
  const { pluginImage, url, pluginName, gradientString } = props;
  return (
    <div>
      <Link
        href={url}
        target="_blank"
        sx={{ textDecoration: "none !important" }}
      >
        <Box
          sx={{
            cursor: "pointer",
          }}
        >
          <Box
            position={"relative"}
            sx={{
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                height: "261px",
                borderRadius: "10px",
                background: gradientString,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={pluginImage}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  backdropFilter: "blur(20px)",
                }}
              />
            </Box>
            <Box
              sx={{
                borderRadius: "10px",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                background: gradientString,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {" "}
              <Typography
                align="center"
                variant="h5"
                color="common.white"
                sx={{
                  textDecoration: "none !important",
                  textTransform: "uppercase",
                  padding: "0 50px",
                  fontWeight: 700,
                }}
              >
                {pluginName}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" marginTop={"26px"}>
            <div className="projectBtnDrawer">
              <center>
                <div className="btn-secondary" style={{ padding: "14px 52px" }}>
                  EXPLORE
                </div>
              </center>
            </div>
          </Box>
        </Box>
      </Link>
    </div>
  );
};

function Tech(props) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Studio DMI | Tech</title>
        <meta charset="UTF-8" />
        <meta name="description" content="tudio DMI also features and incredible tech division featuring software based plugins with collaborations from names like Josh Gudwin, Hardwell, Mike Dean, Greg Wells, Dave Pensado and more." />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        sx={{ background: "#fff" }}
      >
        <Carousel
          showThumbs={false}
          showIndicators={true}
          swipeable={true}
          emulateTouch={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={4000}
          dynamicHeight
        >
          <Link
            href="https://www.acustica-audio.com/shop/products/ELREY"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/El-ReyV2.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/MAGICFLOW"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/Banner_MF.jpg"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/DIAMONDDS"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/newbanners/Saturator-min.webp"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/SPACECONTROL"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/newbanners/SpaceControl-min.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/DIAMONDTS"
            target="_blank"
          >
            <div style={{ cursor: "pointer", }}>
              <img
                src="/images/tech/newbanners/Diamond_Transient-min.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/DIAMONDLIFT"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/newbanners/Diamond_Lift3-min.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/DIAMONDEQ"
            target="_blank"
          >
            <div
              style={{
                cursor: "pointer",
                height: "calc(100vw / 2.36)",
                backgroundImage: `url(/images/tech/newbanners/ColorEQ-3-min.png)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <img
                src="/images/tech/newbanners/ColorEQ-3-min.png"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  backdropFilter: "blur(25px)",
                }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/HWMC"
            target="_blank"
          >
            <div style={{ cursor: "pointer", }}>
              <img
                src="/images/tech/HOWIE_DSK.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/PENSADOEQ"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/newbanners/PensadoEQ-min.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/GAINSTATION"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/newbanners/Gainstation2-min.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/GAINSTATIONBOOST"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/ACQUA-Gainstation2Boost.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/GAINSTATIONVSM"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/ACQUA-Gainstation2VSM.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
          <Link
            href="https://www.acustica-audio.com/shop/products/ELREY"
            target="_blank"
          >
            <div style={{ cursor: "pointer",  }}>
              <img
                src="/images/tech/ACQUA-El_Rey.png"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          </Link>
        </Carousel>
      </Box>
      <Box
        sx={{
          mt: 3,
          backgroundImage: `url(/images/tech/bgr-tech1.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, md: 5 }}
          direction="row"
          justifyContent={"center"}
          sx={{ padding: { xs: "30px 10px", sm: "50px" } }}
        >
          <Grid item xs={12}  md={6} lg={5} xl={4} >
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/ELREY"
              }
              gradientString={
                "linear-gradient(110.04deg, rgba(240, 48, 48, 0.6) 26.7%, rgba(10, 27, 72, 0.6) 66.6%, rgba(34, 5, 5, 0.6) 89.75%)"
              }
              pluginImage={"/images/tech/El-ReyV2.png"}
              pluginName={"El ReyV2"}
            />
          </Grid>
          <Grid item xs={12}  md={6} lg={5} xl={4} >
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/MAGICFLOW"
              }
              gradientString={
                "linear-gradient(110.04deg, rgba(240, 48, 48, 0.6) 26.7%, rgba(10, 27, 72, 0.6) 66.6%, rgba(34, 5, 5, 0.6) 89.75%)"
              }
              pluginImage={"/images/tech/Banner_MF.jpg"}
              pluginName={"Magic Flow"}
            />
          </Grid>
          <Grid item xs={12}  md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/SPACECONTROL"
              }
              gradientString={
                "linear-gradient(123.97deg, rgba(118, 147, 108, 0.6) 24.71%, rgba(20, 65, 54, 0.6) 54.38%, rgba(21, 35, 70, 0.6) 82.26%)"
              }
              pluginImage={"/images/tech/SC_Hardwell.png"}
              pluginName={"SPACE CONTROL"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={"https://www.acustica-audio.com/shop/products/DIAMONDDS"}
              gradientString={
                "linear-gradient(123.97deg, rgba(118, 147, 108, 0.6) 24.71%, rgba(20, 65, 54, 0.6) 54.38%, rgba(21, 35, 70, 0.6) 82.26%)"
              }
              pluginImage={"/images/tech/Diamond_Dynamic_Saturator_teaser_003-min.webp"}
              pluginName={"Diamond Dynamic Saturator"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={"https://www.acustica-audio.com/shop/products/DIAMONDLIFT"}
              gradientString={
                "linear-gradient(72.18deg, rgba(124, 125, 51, 0.6) 29.79%, rgba(131, 87, 21, 0.6) 58.54%, rgba(69, 41, 7, 0.6) 90.35%)"
              }
              pluginImage={"/images/tech/Diamond3_LIFT_.png"}
              pluginName={"Diamond - Lift 3"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/DIAMONDEQ"
              }
              gradientString={
                "linear-gradient(213.3deg, rgba(106, 64, 236, 0.6) 21.3%, rgba(10, 10, 78, 0.6) 61.27%, rgba(52, 54, 200, 0.6) 84.47%)"
              }
              pluginImage={"/images/tech/Diamond_Color_EQ-3.png"}
              pluginName={"Diamond - Color Eq 3"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={"https://www.acustica-audio.com/shop/products/HWMC"}
              gradientString={
                "linear-gradient(303.85deg, rgba(80, 18, 30, 0.6) 17.31%, rgba(81, 10, 22, 0.6) 40.91%, rgba(188, 43, 75, 0.6) 67.01%)"
              }
              pluginImage={"/images/tech/HWMC.png"}
              pluginName={"HWMC - Howie Weinberg Mastering Console"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={"https://www.acustica-audio.com/shop/products/PENSADOEQ"}
              gradientString={
                "linear-gradient(123.97deg, rgba(118, 147, 108, 0.6) 24.71%, rgba(20, 65, 54, 0.6) 54.38%, rgba(21, 35, 70, 0.6) 82.26%)"
              }
              pluginImage={"/images/tech/Pensado_Eq2.png"}
              pluginName={"Pensado EQ 2"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={"https://www.acustica-audio.com/shop/products/GAINSTATION"}
              gradientString={
                "linear-gradient(316.6deg, rgba(90, 45, 15, 0.6) 31.58%, rgba(173, 52, 3, 0.6) 58.2%, rgba(255, 157, 38, 0.6) 87.66%)"
              }
              pluginImage={"/images/tech/Gainstation2.png"}
              pluginName={"Gainstation 2"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/GAINSTATIONBOOST"
              }
              gradientString={
                "linear-gradient(203.09deg, rgba(173, 160, 210, 0.6) 16.61%, rgba(41, 47, 104, 0.6) 47.91%, rgba(46, 21, 70, 0.6) 77.31%)"
              }
              pluginImage={"/images/tech/ACQUA-Gainstation2Boost.png"}
              pluginName={"Gainstation 2 Boost"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/GAINSTATIONVSM"
              }
              gradientString={
                "linear-gradient(137.15deg, rgba(146, 32, 31, 0.6) 24.06%, rgba(64, 33, 47, 0.6) 51.42%, rgba(70, 78, 96, 0.6) 76.8%)"
              }
              pluginImage={"/images/tech/ACQUA-Gainstation2VSM.png"}
              pluginName={"Gainstation 2 VSM"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={"https://www.acustica-audio.com/shop/products/ELREY"}
              gradientString={
                "linear-gradient(203.09deg, rgba(119, 62, 31, 0.6) 16.61%, rgba(162, 77, 47, 0.6) 47.91%, rgba(179, 149, 128, 0.6) 77.31%)"
              }
              pluginImage={"/images/tech/El-Rey.png"}
              pluginName={"El Rey"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "https://www.acustica-audio.com/shop/products/DIAMONDTS"
              }
              gradientString={
                "linear-gradient(110.04deg, rgba(240, 48, 48, 0.6) 26.7%, rgba(10, 27, 72, 0.6) 66.6%, rgba(34, 5, 5, 0.6) 89.75%)"
              }
              pluginImage={"/images/tech/Diamond_Transient.png"}
              pluginName={"Diamond - Transient"}
            />
          </Grid>

          {/* Hardware Products */}
          {/* <Grid item xs={12} md={10}>
            <Typography variant="h4" sx={{color:"#FFFF"}}>
              Hardware
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "/products/rockruepel-sidechain-one-vca-compressor"
              }
              gradientString={
                "linear-gradient(110.04deg, rgba(240, 48, 48, 0.6) 26.7%, rgba(10, 27, 72, 0.6) 66.6%, rgba(34, 5, 5, 0.6) 89.75%)"
              }
              pluginImage={"/images/tech/hw/SC_single_front.jpg"}
              pluginName={"ROCKRUEPEL SIDECHAIN.ONE VCA COMPRESSOR"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <PluginBox
              url={
                "/products/rockruepel-sidechain-one-vca-compressor-stereo-bundle"
              }
              gradientString={
                "linear-gradient(110.04deg, rgba(240, 48, 48, 0.6) 26.7%, rgba(10, 27, 72, 0.6) 66.6%, rgba(34, 5, 5, 0.6) 89.75%)"
              }
              pluginImage={"/images/tech/hw/SC_bundle_front.jpg"}
              pluginName={"ROCKRUEPEL SIDECHAIN.ONE VCA COMPRESSOR - STEREO BUNDLE"}
            />
          </Grid> */}
          {/* End Hardware Products */}
        </Grid>
      </Box>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundImage: "url(images/bgr-tech.jpg)",
    marginTop: "76px",
    height: "auto",
    overflowY: "hidden",
    [theme.breakpoints.down("md")]: {
      marginTop: "64px",
    },
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  imageBtn: {
    cursor: "pointer",
  },
  verticalDivider: {
    height: "auto",
    width: "2px",
    color: "#dbdbdb",
  },
  greyDivider: {
    color: "#dbdbdb",
  },
  textBtn: {
    color: theme.palette.secondary.main,
  },
  historyIndex: {
    width: "100%",
    position: "absolute",
    top: 5,
    left: 0,
  },
  dateBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
    padding: "5px 0",
  },
}));

export default Tech;
