module.exports = {
  userLogin: "user/login",
  createUser: "user/signup",
  createManyUsers: "user/createManyUsers",
  updateUser: "user/update/",
  fetchUsers: "user/all",
  forgotPassRequest: "user/forgotPassRequest",
  resetPassword: "user/resetPassword",
  updatePassword: "user/updatePassword",
  verifyAccount: "user/verifyAccount",
  deleteAccount: "user/deleteAccount",
  allCourses: "course/all",
  myCourses: "course/fetchAllPurchased",
  getSingle: "course/fetchone/",
  createOrder: "order/create",
  approveOrder: "order/approve",
  squareOrder: "order/process-via-square",
  myOrder: "order/my-orders",
  fetchAvailableSessions: "course/fetchsessions",
  contact: "misc/contactus",
  startProject: "misc/projectrequest",
  applyCoupon: "order/apply-coupon",
  showProduct: "/products/",
  fetchTours: "/course/tours",
}
