const INIT_STATE = {
  token: "", //boolean
  userData: {}, //success info warning error
  //text
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      console.log(action.payload);
      return {
        ...state,
        token: action.payload.token,
        userData: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        token: "",
        userData: {},
      };
    default:
      return state;
  }
};
