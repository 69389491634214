import ReactFullpage from "@fullpage/react-fullpage";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";
import Modal from "../components/StartProjectModal";
import StartAProject from "../components/StartAProject";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useTheme } from "@mui/styles";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

function stopWheelPropagation(e) {
  e.stopPropagation()
}

function Home(props) {
  const classes = useStyle();
  const theme = useTheme()
  const List = ({ children, className }) => (
    <div className={className}>{children}</div>
  );
  const [projectModal, setProjectModal] = useState(false)
  const [lModalOpen, setLModalOpen] = useState(false)
  const closeModal = () => setLModalOpen(false)
  const openModal = ()=>setLModalOpen(true)
  let location = useLocation();
  useEffect(() => {
    window?.lazyLoad();
  }, []);

  useEffect(() => {
    let form = document.getElementById("luca-pretolesi-modal1")
    if (form)
      form.addEventListener('wheel', stopWheelPropagation)
    return () => {
      if (form)
        form.removeEventListener('wheel', stopWheelPropagation)
    }
  })
  return (
    <div>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-146650847-1"
        ></script>

        <title>Studio DMI</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="images/favicon.png"
        />

        <link
          type="text/css"
          rel="stylesheet"
          href="css/main.css?v=022621"
          media="all"
        />
        <script type="text/javascript" src="js/jquery-3.4.1.min.js"></script>
        <script type="text/javascript" src="js/fullpage.min.js"></script>
        <script type="text/javascript" src="js/slick.min.js"></script>
        <script type="text/javascript" src="js/scrollreveal.min.js"></script>
        <script
          type="text/javascript"
          src="js/jquery.magnific-popup.min.js"
        ></script>
        <script
          type="text/javascript"
          src="js/jquery.mCustomScrollbar.concat.min.js"
        ></script>
        <script type="text/javascript" src="js/jcf.js"></script>
        <script type="text/javascript" src="js/jcf.checkbox.js"></script>
        <script type="text/javascript" src="js/main.js"></script>
      </Helmet>

      <div className="page-wrap">
        {/* <header class="main-header">
          <div class="header-inner">
            <a href="#" class="btn-menu" title="">
              <span></span>
            </a>
            <div class="logo-holder">
              <a href="/" class="logo-holder">
                <img src="images/logo-studio-dmi-white-red.png" alt="" />
              </a>
            </div>
            <div class="nav-holder">
              <nav class="main-nav">
                <ul class="no-style">
                  <li class="active">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="featured-work">Featured Work</a>
                  </li>
                  <li>
                    <a href="studios">Studios</a>
                  </li>
                  <li>
                    <a href="edu">EDU</a>
                  </li>
                  <li>
                    <a href="tech">Tech</a>
                  </li>
                  <li>
                    <a href="https://media.studiodmi.com">Media</a>
                  </li>
                </ul>
              </nav>
              <div class="button-holder">
                <a href="/#studio-services">Start your project</a>
              </div>
            </div>
          </div>
        </header> */}

        <ReactFullpage
          //fullpage options
          licenseKey={"B1B8F4FA-C4BC47C2-BB0BD453-8FCE1D29"}
          scrollingSpeed={500} /* Options here */
          anchors={["studio-dmi", "luca-pretolesi", "studio-services"]}
          navigationTooltips={[
            "studio dmi",
            "luca pretolesi",
            "studio services",
          ]}
          responsiveWidth={800}
          showActiveTooltip={true}
          navigationPosition={"left"}
          menu={"#fpmenu"}
          navigation={true}
          scrollBar={true}
          css3={true}
          render={({ state, fullpageApi }) => {
            console.log(state, fullpageApi);
            console.log(state, "mystate here");
            let indexSection = null;

            indexSection = fullpageApi?.getActiveSection().index;
            return (
              <ReactFullpage.Wrapper>
                <div className="section enginners-section">
                  <div
                    className="card-image bgr-style mobile-hide"
                    style={{
                      backgroundImage: "url(images/bgr-slide-1-low.jpg)",
                    }}
                    data-image-full="images/bgr-slide-1.jpg"
                  >
                    <img className="back-img" src="" alt="" />
                  </div>
                  <div
                    className="card-image bgr-style mobile-show"
                    // style="background-image: url(images/bgr-slide-1-low.jpg);"
                    data-image-full="images/bgr-slide-1.jpg"
                  >
                    <img className="back-img" src="" alt="" />
                  </div>
                  <div className="container">
                    <div className="title-holder">
                      <Fade
                        bottom
                        delay={250}
                        duration={1000}
                        when={
                          indexSection == 0 ||
                          location.hash == "#studio-dmi" ||
                          window.innerWidth < 800
                        }
                      >
                        <p>Mixing and Mastering Studio led by multi Grammy Award Winner Luca Pretolesi</p>
                      </Fade>
                    </div>
                    <div>
                      {/* <div className="stats-wrapp">
                        <Fade
                          bottom
                          delay={500}
                          duration={1000}
                          when={
                            indexSection == 0 || location.hash == "#studio-dmi"
                          }
                        >
                          <div className="stats-item">
                            <div className="number-holder">5x</div>
                            <div className="text-holder">
                              <div className="icon-holder">
                                <span className="icon icon-gma"></span>
                              </div>

                              <h3>
                                Golden Melody <br />
                                Awards
                              </h3>
                            </div>
                          </div>
                        </Fade>

                        <Fade
                          bottom
                          delay={700}
                          duration={1000}
                          when={
                            indexSection == 0 ||
                            location.hash == "#studio-dmi" ||
                            window.innerWidth < 800
                          }
                        >
                          <div className="stats-item">
                            <div className="number-holder grammy">9x</div>
                            <div className="text-holder">
                              <div className="icon-holder">
                                <span className="icon icon-grammy"></span>
                              </div>

                              <h3 className="grammy">
                                Grammy <br />
                                Nominations
                              </h3>
                            </div>
                          </div>
                        </Fade>
                        <Fade
                          bottom
                          delay={1000}
                          duration={1000}
                          when={
                            indexSection == 0 ||
                            location.hash == "#studio-dmi" ||
                            window.innerWidth < 800
                          }
                        >
                          <div className="stats-item">
                            <div className="number-holder">5x</div>
                            <div className="text-holder">
                              <div className="icon-holder">
                                <span className="icon icon-streams"></span>
                              </div>

                              <h3>
                                Diamond <br />
                                Records
                              </h3>
                            </div>
                          </div>
                        </Fade>
                      </div> */}
                    </div>
                  </div>
                  <div className="scroll-down-holder ">
                    <a href="#luca-pretolesi" className="scroll-down">
                      <img src="images/svg/caret-down.svg" alt="" />
                    </a>
                  </div>
                </div>
                <div className="section enginner-single-section">
                  <div
                    className="card-image bgr-style"
                    // style="background-image: url(images/bgr-slide-luca-pretolesi-low.jpg);"
                    data-image-full="images/bgr-slide-luca-pretolesi.jpg"
                  >
                    <img className="back-img" src="" alt="" />
                  </div>
                  <div className="container">
                    <div className="enginner-section-sidebar">
                      <div className="enginner-sidebar-inner">
                        <Fade
                          bottom
                          delay={250}
                          duration={1000}
                          when={
                            indexSection == 1 ||
                            location.hash == "#luca-pretolesi" ||
                            window.innerWidth < 800
                          }
                        >
                          <div className="postion ">
                            Mixing & Mastering Engineer
                          </div>
                        </Fade>
                        <Fade
                          bottom
                          delay={300}
                          duration={1000}
                          when={
                            indexSection == 1 ||
                            location.hash == "#luca-pretolesi" ||
                            window.innerWidth < 800
                          }
                        >
                          <div className="title-holder ">
                            <h2>Luca Pretolesi</h2>
                            <ul className="no-style social-icons">
                              <li>
                                <a
                                  href="https://www.instagram.com/lucapretolesi/"
                                  target="_blank"
                                >
                                  <img
                                    src="images/svg/instagram-icon.png"
                                    alt=""
                                  />
                                </a>
                              </li>
                            </ul>
                            <ul className="no-style awards">
                              <li>
                                <img src="images/award-3.png" alt="" />
                              </li>
                              <li>
                                <img src="images/award-1.png" alt="" />
                              </li>
                              <li>
                                <img src="images/award-2.png" alt="" />
                              </li>
                              <li>
                                <img src="images/award-4.png" alt="" />
                              </li>
                            </ul>
                          </div>
                        </Fade>
                        <Fade
                          delay={600}
                          duration={1000}
                          when={
                            indexSection == 1 ||
                            location.hash == "#luca-pretolesi" ||
                            window.innerWidth < 800
                          }
                        >
                          <div className="">
                            <div className="text-holder">
                              <p
                                onClick={(e) => { e.stopPropagation() }}

                              >
                                Logging well over 40,000 hours in the studio with over 25 years of experience, Luca Pretolesi’s prolific career has led him to his status as the world-renowned mixing and mastering engineer he’s known as today. With awards ranging from Grammy wins & multiple nominations, Platinum, Diamond, Billboard, and Golden Melody awards as well as founding and creating Studio DMI, his state-of-the-art facility based in Las Vegas, NV USA, Luca, and his Studio DMI brand are synonymous with unrivaled quality and style sought out by the biggest names in electronic and pop music.
                                <br/>
                                <a
                                  // href="#luca-pretolesi-modal"
                                  className="read-more open-modal"
                                  style={{cursor: "pointer"}}
                                  onClick={openModal}
                                >
                                  Read more
                                </a>
                              </p>

                              <div className="youtube-holder">
                                <iframe
                                  width="560"
                                  height="315"
                                  src="https://www.youtube.com/embed/ihti7w091NA"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section section-2">
                  <div className="left-col">
                    <div
                      className="card-image bgr-style"
                      style={{
                        backgroundImage: "url(images/bgr-slide-3-2-low.jpg)",
                      }}
                      data-image-full="images/bgr-slide-3-2.jpg"
                    >
                      <img className="back-img" src="" alt="" />
                    </div>
                    <div className="container">
                      <div className="text-inner">
                        <Fade
                          bottom
                          delay={250}
                          duration={1000}
                          when={
                            indexSection == 2 ||
                            location.hash == "#studio-services" ||
                            window.innerWidth < 800
                          }
                        >
                          <h2 className="">Studio Services</h2>
                        </Fade>
                        {/* <Fade
                          bottom
                          delay={500}
                          duration={1000}
                          when={
                            indexSection == 2 ||
                            location.hash == "#studio-services" ||
                            window.innerWidth < 800
                          }
                        >
                          <h3 className="position ">Service Coordinator</h3>
                        </Fade> */}
                        <Fade
                          bottom
                          delay={1000}
                          duration={1000}
                          when={
                            indexSection == 2 ||
                            location.hash == "#studio-services" ||
                            window.innerWidth < 800
                          }
                        >
                          <p className="">
                            StudioDMI has quickly become one of the world’s most
                            renowned mixing and mastering studios with work
                            featuring some of music’s biggest artists in all
                            genres. Our goal is simple… Make Music Better. Let
                            our team pair your vision with the, signature sound
                            StudioDMI is known for.
                          </p>
                        </Fade>
                        <Fade
                          delay={1200}
                          duration={1000}
                          when={
                            indexSection == 2 ||
                            location.hash == "#studio-services" ||
                            window.innerWidth < 800
                          }
                        >
                          <ul className="no-style social-icons ">
                            <li>
                              <a
                                href="https://www.facebook.com/StudioDMI/"
                                target="_blank"
                              >
                                <img
                                  src="images/svg/facebook-icon.svg"
                                  alt=""
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.instagram.com/StudioDMI"
                                target="_blank"
                              >
                                <img
                                  src="images/svg/instagram-icon.png"
                                  alt=""
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/StudioDMI"
                                target="_blank"
                              >
                                <img src="images/svg/twitter-icon.svg" alt="" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/user/StudioDMI/videos"
                                target="_blank"
                              >
                                <img src="images/svg/youtube-icon.svg" alt="" />
                              </a>
                            </li>
                          </ul>
                        </Fade>
                        <br />
                        <center>
                          {" "}
                          {/* <Modal>
                            <div
                              className="projectBtnDrawer"
                              style={{ maxWidth: 175 }}
                            >
                              <center>
                                <div className="btn-secondary">
                                  //ßStart your project
                                </div>
                              </center>
                            </div>
                          </Modal> */}
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <Dialog
              open={lModalOpen}
              onClose={closeModal}
              PaperProps={{
                style: {
                  backgroundColor: theme.palette?.primary["dark"],
                  color: "#FFFF",
                  [theme.breakpoints.up('sm')]: { padding: "2rem" },
                  overflow: "auto !important"
                }
              }}
            >
              <DialogTitle align="center" fontSize={32}>
                <div>
                  <IconButton onClick={closeModal} sx={{ color: "#FFFF", float: 'right', mr: "-1rem" }}>
                    <CancelPresentationIcon fontSize="large" titleAccess="Close" color="#FFFF" />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="modal-primary-wrap" id="luca-pretolesi-modal1">
                  <div className="left-col">
                    <h3 className="h1-title">Luca Pretolesi</h3>
                    <ul className="no-style awards">
                      <li>
                        <img src="images/award-3.png" alt="" />{" "}
                      </li>
                      <li>
                        <img src="images/award-1.png" alt="" />
                      </li>
                      <li>
                        <img src="images/award-2.png" alt="" />
                      </li>
                      <li>
                        <img src="images/award-4.png" alt="" />
                      </li>
                    </ul>
                  </div>
                  <div className="right-col scroll-right text-engineer-modal">
                    <p>
                      Logging well over 40,000 hours in the studio with over 25 years of experience, Luca Pretolesi’s prolific career has led him to his status as the world-renowned mixing and mastering engineer he’s known as today. With awards ranging from Grammy wins & multiple nominations, Platinum, Diamond, Billboard, and Golden Melody awards as well as founding and creating Studio DMI, his state-of-the-art facility based in Las Vegas, NV USA, Luca, and his Studio DMI brand are synonymous with unrivaled quality and style sought out by the biggest names in electronic and pop music.
                    </p>
                    <p>
                      Luca has had the honor of working with top artists around the globe such as Drake,
                      Skrillex, Steve Aoki, Diplo, David Guetta, Gordo, Gareth Emery, Dillon Francis, J Balvin,
                      Snoop Lion, Above & Beyond, G-Dragon, Jolin Tsai and many more. Luca's Grammy nominations
                      include Diplo’s Self-Titled Album in 2022, Diplo & Sidepiece – “On My Mind” in 2021 for Best
                      Dance Recording, J Balvin & Willy William - "Mi Gente" Record of the Year,
                      Steve Aoki – "Wonderland" Best Dance Album and Snoop Lion' "Reincarnation" Best Reggae Album.
                    </p>
                    <p>
                      Studio DMI Highlighted Credits: Above &amp; Beyond, Alok, Baby
                      K, Bassjackers, Benny Benassi, BIGBANG, BLACKPINK, Borgeous,
                      Borgore, Bruno Martini, Cedric Gervais, Dada Life, David Guetta,
                      Dillon Francis, Dimitri Vegas &amp; Like Mike, Diplo, DJ
                      Shiftee, DVBBS, Fabio Rovazzi, Flo Rida, FTampa, Gareth Emery,
                      Gianluca Vacchi, G-Dragon, Jason Derulo, J Balvin, Jolin Tsai,
                      Jovanotti, K?D, KSHMR, Kura, Lil Jon, MAKJ, Major Lazer, Merk
                      &amp; Kremont, Morgan Page, Moti, Nervo, NGHTMRE, Paul
                      Oakenfold, Pegboard Nerds, Play-N-Skillz, Rusko, Sandro Cavazza,
                      SAYMYNAME, Snoop Lion, Sofi Tukker, Steve Aoki, TJR, Willy
                      William, Zeds Dead, 2NE1.
                    </p>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
      </div>
      <div
        id="fp-nav"
        className="fp-left fp-show-active"
        style={{ marginTop: -93 }}
      >
        <ul>
          <li>
            <a data-menuanchor="studio-dmi" href="#studio-dmi"></a>
          </li>
          <li>
            <a data-menuanchor="luca-pretolesi" href="#luca-pretolesi"></a>
          </li>
          <li>
            <a data-menuanchor="studio-services" href="#studio-services"></a>
          </li>
        </ul>
      </div>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    left: 0,
    padding: "20px 100px",
  },
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
  darkTextfield: {
    color: "#fff",
    border: "2px solid #2E2F2F",
  },
}));

export default Home;
