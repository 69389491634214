// import logo from "./logo.svg";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player/vimeo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import Image from "../components/home/Image";
import { getSingleCourse } from "../redux/action/course.action";
import { addCartItem, removeCartItem } from "./../redux/action/cart.action";
function UserVideoCourse() {
  const classes = useStyle();
  const [videos, setVideos] = useState([]);
  const [course, setCourse] = useState({});
  const userVideos = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.items);
  useEffect(() => {
    console.log("couse");
    dispatch(getSingleCourse(params.cid)).then((data) => {
      setVideos(data.videos);
      setCourse(data);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.vid]);

  let indexcrr = videos.findIndex((i) => i._id == params.vid);

  let currentVideoIndex = indexcrr > -1 ? indexcrr : 0;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Studio DMI |" + course?.name}</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Container maxWidth="lg">
        <br />

        <Grid
          container
          spacing={4}
          sx={{ paddingRight: { md: "50px 100px" } }}
          align-items="center"
          justify-content="center"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid item lg={9} xs={12} sm={12}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item md={6} xs={12} sm={6}>
                <Box display="flex">
                  <Typography variant="h4" color="secondary">
                    {course.name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <br />

            {/* <img src="/userVideo2.png" className={classes.videoThumb} /> */}
            {videos?.[currentVideoIndex]?.isPurchased ? (
              <div className={classes.playerWrapper}>
                <ReactPlayer
                  url={videos?.[currentVideoIndex]?.link}
                  width="100%"
                  height="100%"
                  className={classes.reactPlayer}
                  config={{
                    playerOptions: {
                      controls: true,
                    },
                  }}
                  style={{
                    borderRadius: "4px",
                    boxShadow: "5px 10px 20px rgba(42, 26, 39, 0.38)",
                  }}
                />
              </div>
            ) : (
              <Image
                link={videos?.[currentVideoIndex]?.link}
                // className={classes.reactPlayer}
                lockSize={108}
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            )}

            <br />

            <Typography
              variant="h4"
              color="initial"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              {videos?.[currentVideoIndex]?.title}
            </Typography>

            <Typography gutterBottom variant="h6" color="initial">
              {" "}
              {videos?.[currentVideoIndex]?.description}
            </Typography>
            <br />
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item md={6} xs={12} sm={6}>
                {!course.isPurchased && (
                  <Button
                    align="center"
                    fullWidth
                    color="secondary"
                    variant="contained"
                    size="small"
                    gutterBottom
                    onClick={(e) => {
                      cart[course._id]
                        ? dispatch(removeCartItem(course._id))
                        : dispatch(
                            addCartItem({
                              _id: course._id,
                              name: course.name,
                              price: course.price,
                              courseId: course._id,
                              type: "bundle",
                            })
                          );
                    }}
                    // disabled={cart[course._id]}
                  >
                    {cart[course._id]
                      ? "Remove bundle from cart"
                      : "Buy bundle - $" + course.price}
                  </Button>
                )}
              </Grid>
              <Grid item md={6} xs={12} sm={6}>
                {!course.videos?.[currentVideoIndex].isPurchased && (
                  <Button
                    align="center"
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    size="small"
                    gutterBottom
                    onClick={(e) => {
                      cart[videos?.[currentVideoIndex]?._id]
                        ? dispatch(
                            removeCartItem(videos?.[currentVideoIndex]?._id)
                          )
                        : dispatch(
                            addCartItem({
                              _id: videos?.[currentVideoIndex]?._id,
                              name: videos?.[currentVideoIndex].title,
                              videoId: videos?.[currentVideoIndex]._id,
                              courseId: course._id,
                              type: "video",
                            })
                          );
                    }}
                  >
                    {cart[videos?.[currentVideoIndex]?._id]
                      ? "Remove video from cart"
                      : "Buy this video - $" +
                        videos?.[currentVideoIndex]?.price}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} xs={12} sm={12}>
            <Grid item xs={12}>
              {" "}
              <Typography variant="h4" gutterBottom>
                All videos
              </Typography>
            </Grid>
            <Grid container spacing={4} overflow="auto">
              {videos.map((item) => {
                return (
                  <Grid item lg={12} xs={12} sm={6}>
                    <Link
                      to={`/edu/courses/${course._id}/${item._id}`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      <Box className={classes.videoDiv}>
                        <Image
                          link={item.link}
                          withPlay={item.isPurchased}
                          className={classes.videoThumb}
                        />
                        <Box>
                          <Typography variant="h6" gutterBottom align="center">
                            {item.title}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                    {course.type !== "bundle" && !item.isPurchased && (
                      <Button
                        align="center"
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        size="small"
                        gutterBottom
                        onClick={(e) => {
                          cart[item?._id]
                            ? dispatch(removeCartItem(item._id))
                            : dispatch(
                                addCartItem({
                                  ...item,
                                  name: item.title,
                                  videoId: item._id,
                                  courseId: course._id,
                                  type: "video",
                                })
                              );
                        }}
                      >
                        {cart[item?._id]
                          ? "Remove video from cart"
                          : "Buy this video - $" + item.price}
                      </Button>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    marginTop: 100,
    marginBottom: 100,
  },

  menuBtn: {
    cursor: "pointer",
  },

  greyDivider: {
    color: "#dbdbdb",
  },
  textBtn: {
    color: theme.palette.secondary.main,
  },
  videoDiv: {
    borderRadius: "4px",
  },
  videoThumb: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    borderRadius: "4px",
    boxShadow: "5px 10px 20px rgba(42, 26, 39, 0.38)",
  },
  videoTitle: {
    position: "absolute",
    bottom: 6,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.63)",
    borderRadius: "4px",
    padding: "0 15px",
  },
  dateBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
    padding: "5px 0",
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

export default UserVideoCourse;
