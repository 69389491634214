// import logo from "./logo.svg";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import MixMasterSeason from "../components/popovers/MixMasterSeason";
import { fetchMyCourse } from "../redux/action/course.action";

function UserVideoCourses(props) {
  const classes = useStyle();
  const [courses, setCourses] = useState([]);
  const [selected, setSelected] = useState({});
  const userVideos = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  useEffect(() => {
    document.documentElement.style.overflow = "auto"; // firefox, chrome
    document.body.scroll = "yes"; // ie only
    props.fetchMyCourse().then((data) => {
      setCourses(data);
      setSelected(data?.[0]);
    });
  }, []);
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Studio DMI | User Courses</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {courses.length ? (
        <Container maxWidth="lg">
          <br />

          <MixMasterSeason
            setSelected={(data) =>
              setSelected(courses.find((i) => i._id == data._id))
            }
            allSeries={courses.map((i) => {
              return { name: i.videos[0].name, _id: i._id };
            })}
          >
            <Box display="flex">
              <Typography variant="h4" color="secondary">
                {selected?.videos?.[0].name}
              </Typography>
              &nbsp;&nbsp;&nbsp;
              <Typography variant="h4">
                <KeyboardArrowDown />
              </Typography>
            </Box>
          </MixMasterSeason>
          <br />
          <Grid container spacing={3}>
            {selected?.videos?.map((item) => (
              <Grid item md={4} xs={12} sm={6}>
                <ButtonBase
                  className={classes.videoDiv}
                  onClick={() =>
                    navigate(`/my-video/${item._id}/${item.videos._id}`)
                  }
                >
                  <img src="/uservideo1.png" className={classes.videoThumb} />
                  <Box className={classes.videoTitle}>
                    <Typography variant="h6" color="textSecondary">
                      {item.videos.title}
                    </Typography>
                  </Box>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="sm">
          <Box display={"flex"} justifyContent={"center"} marginTop={"200px"}>
            <Typography variant="h5" color="initial">
              Courses opted by you will be listed here
            </Typography>
          </Box>
        </Container>
      )}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    marginTop: 100,
  },

  menuBtn: {
    cursor: "pointer",
  },

  greyDivider: {
    color: "#dbdbdb",
  },
  textBtn: {
    color: theme.palette.secondary.main,
  },
  videoDiv: {
    position: "relative",
    borderRadius: "4px",
    cursor: "pointer",
  },
  videoThumb: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    borderRadius: "4px",
  },
  videoTitle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    minHeight: 50,
    backgroundColor: "rgba(0, 0, 0, 0.63)",
    borderRadius: "4px",
    padding: "0 15px",
  },
  dateBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
    padding: "5px 0",
  },
}));

export default connect(null, { fetchMyCourse })(UserVideoCourses);
