const INIT_STATE = {
  snackbar: false, //boolean
  type: "success", //success info warning error
  message: "hi", //text
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UPDATE_SNACKBAR":
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
