import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const IconCart = (props) => {
  return (
    <SvgIcon
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.4375 9H23.0156L16.9688 1.92188C16.5469 1.45312 15.8438 1.40625 15.375 1.78125C14.9062 2.20312 14.8594 2.90625 15.2344 3.375L20.0625 9H6.89062L11.7188 3.375C12.0938 2.90625 12.0469 2.20312 11.5781 1.78125C11.1094 1.40625 10.4062 1.45312 9.98438 1.92188L3.9375 9H0.5625C0.234375 9 0 9.28125 0 9.5625V10.6875C0 11.0156 0.234375 11.25 0.5625 11.25H1.40625L2.71875 20.5781C2.85938 21.7031 3.79688 22.5 4.92188 22.5H22.0312C23.1562 22.5 24.0938 21.7031 24.2344 20.5781L25.5469 11.25H26.4375C26.7188 11.25 27 11.0156 27 10.6875V9.5625C27 9.28125 26.7188 9 26.4375 9ZM22.0312 20.25H4.92188L3.70312 11.25H23.25L22.0312 20.25ZM14.625 13.875C14.625 13.2656 14.1094 12.75 13.5 12.75C12.8438 12.75 12.375 13.2656 12.375 13.875V17.625C12.375 18.2812 12.8438 18.75 13.5 18.75C14.1094 18.75 14.625 18.2812 14.625 17.625V13.875ZM19.875 13.875C19.875 13.2656 19.3594 12.75 18.75 12.75C18.0938 12.75 17.625 13.2656 17.625 13.875V17.625C17.625 18.2812 18.0938 18.75 18.75 18.75C19.3594 18.75 19.875 18.2812 19.875 17.625V13.875ZM9.375 13.875C9.375 13.2656 8.85938 12.75 8.25 12.75C7.59375 12.75 7.125 13.2656 7.125 13.875V17.625C7.125 18.2812 7.59375 18.75 8.25 18.75C8.85938 18.75 9.375 18.2812 9.375 17.625V13.875Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};
export default IconCart;
