// import logo from "./logo.svg";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { login } from "../../redux/action/auth.action";
import { contactUs } from "../../redux/action/utils.action";

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  phone: yup.string("Enter your phone number").required("Phone is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  message: yup
    .string("Enter your password")

    .required("message is required"),
});

function SignIn(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const [formSubmitted, setformSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleLogin = (values) => {
    setLoading(true);
    props
      .login({
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location = "/";
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      contactUs(values)
        .then((data) => {
          toast.success(data.message, { position: "bottom-right" });
          setformSubmitted(true);
          resetForm();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      height="100%"
    >
      <Box display="flex" className={classes.backButton}>
        <ButtonBase onClick={() => window.history.back()}>
          <ArrowBack />
          &nbsp; Back
        </ButtonBase>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
      >
        <Box display="flex" justifyContent="center" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            maxWidth="550px"
            paddingLeft="24px"
            paddingRight="24px"
          >
            <Typography align="center" variant="h3" color="initial">
              {formSubmitted ? "Thank You" : "Contact Us"}
            </Typography>
            <Typography
              align="center"
              variant="h6"
              color="initial"
              sx={{ my: 3 }}
            >
              Your query has been submitted to our support team , we will get in
              touch to you soon!
            </Typography>
            <br />
            <br />
            {!formSubmitted ? (
              <form onSubmit={formik.handleSubmit}>
                <Box display="flex" justifyContent="center">
                  <Box display="flex" flexDirection="column">
                    {/* <Typography align="left" variant="h4" color="textSecondary">
                    Contact us
                  </Typography>
                  <br /> */}
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          // onChange={(e) => { setBookingData({ ...bookingData, name: e.target.value }) }}
                          onChange={formik.handleChange}
                          placeholder="Name"
                          variant="outlined"
                          style={{ background: "white" }}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          fullWidth
                          id="surname"
                          name="surname"
                          // onChange={(e) => { setBookingData({ ...bookingData, surname: e.target.value }) }}
                          onChange={formik.handleChange}
                          placeholder="Surname"
                          variant="outlined"
                          style={{ background: "white" }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          // onChange={(e) => { setBookingData({ ...bookingData, email: e.target.value }) }}
                          onChange={formik.handleChange}
                          placeholder="Email"
                          variant="outlined"
                          style={{ background: "white" }}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          fullWidth
                          id="phone"
                          name="phone"
                          // onChange={(e) => { setBookingData({ ...bookingData, phone: e.target.value }) }}
                          onChange={formik.handleChange}
                          placeholder="Phone number"
                          variant="outlined"
                          style={{ background: "white" }}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <TextField
                          fullWidth
                          id="message"
                          name="message"
                          style={{ background: "white" }}
                          multiline
                          rows={2}
                          // onChange={(e) => { setBookingData({ ...bookingData, message: e.target.value }) }}
                          onChange={formik.handleChange}
                          placeholder="Message"
                          variant="outlined"
                          error={Boolean(touched.message && errors.message)}
                          helperText={touched.message && errors.message}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Box>
              </form>
            ) : (
              " "
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  textBtn: {
    color: "#AAC9DD",
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "50px",
  },
}));

export default connect(null, { login })(SignIn);
