import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const IconSetting = (props) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.2188 9.875L13.875 9.125C14.0312 8.375 14.0312 7.65625 13.875 6.90625L15.2188 6.15625C15.375 6.0625 15.4375 5.875 15.375 5.71875C15.0312 4.59375 14.4375 3.59375 13.6875 2.75C13.5625 2.625 13.375 2.59375 13.2188 2.6875L11.875 3.4375C11.3125 2.96875 10.6875 2.59375 10 2.34375V0.8125C10 0.65625 9.875 0.5 9.6875 0.46875C8.53125 0.1875 7.375 0.21875 6.28125 0.46875C6.09375 0.5 6 0.65625 6 0.8125V2.34375C5.28125 2.59375 4.65625 2.96875 4.09375 3.46875L2.75 2.6875C2.59375 2.59375 2.40625 2.625 2.28125 2.75C1.53125 3.59375 0.9375 4.59375 0.59375 5.71875C0.53125 5.875 0.59375 6.0625 0.75 6.15625L2.09375 6.90625C1.96875 7.65625 1.96875 8.375 2.09375 9.125L0.75 9.875C0.59375 9.96875 0.53125 10.1562 0.59375 10.3125C0.9375 11.4375 1.53125 12.4375 2.28125 13.2812C2.40625 13.4062 2.59375 13.4375 2.75 13.3438L4.09375 12.5938C4.65625 13.0625 5.28125 13.4375 6 13.6875V15.2188C6 15.375 6.125 15.5312 6.28125 15.5938C7.4375 15.8438 8.59375 15.8125 9.6875 15.5938C9.875 15.5312 10 15.375 10 15.2188V13.6875C10.6875 13.4375 11.3125 13.0625 11.875 12.5938L13.2188 13.3438C13.375 13.4375 13.5625 13.4062 13.6875 13.2812C14.4688 12.4375 15.0312 11.4375 15.4062 10.3125C15.4375 10.1562 15.375 9.96875 15.2188 9.875ZM8 10.5C6.59375 10.5 5.5 9.40625 5.5 8C5.5 6.625 6.59375 5.5 8 5.5C9.375 5.5 10.5 6.625 10.5 8C10.5 9.40625 9.375 10.5 8 10.5Z"
        fill="#AAC9DD"
      />
    </SvgIcon>
  );
};
export default IconSetting;
