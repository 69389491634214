import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import React, { useRef, useState } from "react";


const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
    marginTop: 10,
  },
  popoverContent: {
    pointerEvents: "auto",
    border: "1px solid #E8E7E9",
    padding: 16,
    width: "auto",
    boxShadow: "0px 10px 78px rgba(27, 23, 42, 0.25)",
  },

  container: {
    padding: 12,
  },
  headBtns: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  seriesButton: {
    fontWeight: 500,
    cursor: "pointer",
  },
}));

const MixMasterSeason = ({ children, allSeries, setSelected }) => {
  const [openedPopover, setOpenedPopover] = useState(false);

  const [selectedSeries, setSelectedSeries] = useState(null);
  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  const allSeris = [...allSeries];

  return (
    <div>
      <span
        ref={popoverAnchor}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        {children}
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="space-between"
        >
          {allSeris.map((item, index) => (
            <Typography
              variant="button"
              className={classes.seriesButton}
              onClick={() => {
                popoverLeave();
                setSelected(item);
                setSelectedSeries(index);
              }}
              color={index == selectedSeries ? "secondary" : "intial"}
            >
              {item.name}
            </Typography>
          ))}
        </Box>
      </Popover>
    </div>
  );
};

export default MixMasterSeason;
