// import logo from "./logo.svg";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import IconSuccess from "../svgAssets/IconSuccess";


function Success(props) {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <br />
      <br />
      <Box display="flex" justifyContent="center" width="100%">
        <IconSuccess style={{ width: "232px", height: "auto" }} />
      </Box>
      <Typography align="center" variant="h4" color="initial">
        Congratulations!
      </Typography>
      <br />
      <Typography align="center" variant="h5" color="initial">
        Your opt for the course was successful
      </Typography>

      <br />
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  textBtn: {
    color: "#AAC9DD",
  },
}));

export default Success;
