export const addCartItem = (newItem) => (dispatch, getState) => {
  let newCart = { ...getState().cart.items };

  if (newItem.type == "bundle") {
    let allChildVideos = Object.values(newCart).filter(
      (i) => i.courseId == newItem.courseId && newItem.type == "bundle"
    );

    console.log(allChildVideos);
    allChildVideos.forEach((e) => delete newCart[e._id]);
  }
  newCart[newItem._id] = newItem;

  localStorage.setItem("cart", JSON.stringify(newCart));
  dispatch({ type: "SET_CART", payload: newCart });
};

export const removeCartItem = (itemId) => (dispatch, getState) => {
  let newCart = { ...getState().cart.items };
  delete newCart[itemId];

  localStorage.setItem("cart", JSON.stringify(newCart));
  dispatch({ type: "SET_CART", payload: newCart });
};

export const emptyCart = () => (dispatch, getState) => {
  localStorage.setItem("cart", JSON.stringify({}));
  dispatch({ type: "SET_CART", payload: {} });
};
