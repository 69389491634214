import React from "react";
// import NotFound from "../Pages/Standalone/NotFoundDedicated";
// import LoginDedicated from "../Pages/Standalone/LoginDedicated";
import { useDispatch, useSelector } from "react-redux";
import ApplicationRoutes from "./application";
import AuthRoutes from "./auth";
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
function AppRoutes() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  if (localStorage.getItem("cart")) {
    let newCart = JSON.parse(localStorage.getItem("cart"));
    dispatch({ type: "SET_CART", payload: newCart });
  }
  return (
    <>
      {Boolean(token) || Boolean(localStorage.getItem("token")) ? (
        <ApplicationRoutes />
      ) : (
        <AuthRoutes />
      )}
    </>
  );
}

export default AppRoutes;
