import {
    Box, Button, Checkbox, CircularProgress, Dialog, DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel,
    Grid, IconButton, TextField, Typography, Zoom
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/styles";
import { startAProject } from "../redux/action/utils.action";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import * as yup from "yup";

function stopWheelPropagation(e) {
    e.stopPropagation()
}
const validationSchema = yup.object({
    name: yup.string().required(),
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    services: yup.array(yup.string()),
    workWith: yup.array(yup.string()),
    requirement: yup.string().required(),
    deliverFormats: yup.array(yup.string()),
    deliveryDate: yup.date().required("Please provide the target delivery date"),
    linkToProject: yup.string().required("Please provide a link to rough mix"),
    referenceMixLink: yup.string().required("Please provide a link to reference mix"),
    extraInfo: yup.string(),
});

const focusStyle = { '& .Mui-focused': { color: "#FFFF" } }
export default function StartAProject({ children, open, handleClickOpen, setOpen }) {
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false);
    const theme = useTheme()
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values)
            setLoading(true)
            startAProject(values)
                .then(res => {
                    console.log(res)
                    setSubmitted(true)
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => setLoading(false))
        },
    })
    const formRef = useRef()
    useEffect(() => {
        let form = document.getElementById("startProjectForm")
        if (form)
            form.addEventListener('wheel', stopWheelPropagation)
        return () => {
            if (form)
                form.removeEventListener('wheel', stopWheelPropagation)
        }

    })

    const handleClose = () => {
        setOpen(false)
        if (submitted)
            setSubmitted(false)
    }

    return (
        <div>
            <div onClick={handleClickOpen}>
                {children}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={"sm"}
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette?.primary["dark"],
                        color: "#FFFF",
                        [theme.breakpoints.up('sm')]: { padding: "2rem" },
                        overflow: "auto !important"
                    }
                }}
            >
                <DialogTitle align="center" fontSize={32}>
                    Start your project
                    <div>
                        <IconButton onClick={handleClose} sx={{ color: "#FFFF", float: 'right', mt: "-3rem", mr: "-1rem" }}>
                            <CancelPresentationIcon fontSize="large" titleAccess="Close" color="#FFFF" />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {
                        submitted ?
                            <>
                                <Box sx={{ textAlign: 'center', color: "#FFFF" }}>
                                    <Zoom in={submitted}>
                                        <CheckCircleOutlineIcon fontSize="large" />
                                    </Zoom>
                                </Box>
                                <Typography component={"p"} sx={{ pb: 2 }} variant="body1" align="center" textAlign={"center"}>
                                    Thank you! Your request has been submitted, we'll get back to you shortly.
                                </Typography>
                            </>
                            :
                            <form onSubmit={formik.handleSubmit} ref={formRef} id="startProjectForm">
                                <Grid container>
                                    <Grid item xs={12} flex={true} pb={2}>
                                        <FormControl fullWidth required>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Name</FormLabel>
                                            <TextField
                                                required
                                                fullWidth sx={{ border: "1px solid #FFFF", backgroundColor: "#FFFF" }}
                                                name="name"
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                                onChange={formik.handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid items xs={12} pb={2}>
                                        <FormControl fullWidth required>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Email</FormLabel>
                                            <TextField
                                                required
                                                name={"email"}
                                                fullWidth sx={{ border: "1px solid #FFFF", backgroundColor: "#FFFF" }}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                                onChange={formik.handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth sx={focusStyle} required>
                                            <FormLabel sx={{ paddingBottom: "4px" }} required>Which service would you like ?</FormLabel>
                                            <FormGroup>
                                                <Grid container pl={2}>
                                                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                                                        <FormControlLabel
                                                            label="Stereo Master"
                                                            name="services"
                                                            value={"Stereo Master"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Stem Master"
                                                            name="services"
                                                            value={"Stem Master"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Stem Mix/Master"
                                                            name="services"
                                                            value={"Stem Mix/Master"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                                                        <FormControlLabel
                                                            label="Full Mix/Master"
                                                            name="services"
                                                            value={"Full Mix/Master"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Dolby Atmos"
                                                            name="services"
                                                            value={"Dolby Atmos"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Full Mix/Master + Mixing for Dolby Atmos"
                                                            name="services"
                                                            value={"Full Mix/Master + Mixing for Dolby Atmos"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth sx={focusStyle}>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Who are you looking to work with ?</FormLabel>
                                            <Box pl={2}>
                                                <FormControl fullWidth sx={focusStyle}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            label="Luca Pretolesi"
                                                            name="workWith"
                                                            value={"Luca Pretolesi"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}

                                                        />
                                                        <FormControlLabel
                                                            label="Studio DMI"
                                                            name="workWith"
                                                            value={"Studio DMI"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Box>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth required>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>What do you want to retain in your song, and what do you think needs work? *</FormLabel>
                                            <TextField
                                                required
                                                name={"requirement"}
                                                fullWidth sx={{ border: "1px solid #FFFF", backgroundColor: "#FFFF" }}
                                                onChange={formik.handleChange}
                                                placeholder="Feel free to reference tracks if that helps explain"
                                                multiline
                                                error={formik.touched.requirement && Boolean(formik.errors.requirement)}
                                                helperText={formik.touched.requirement && formik.errors.requirement}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth sx={focusStyle}>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Which additional delivery formats would you like to receive ?</FormLabel>
                                            <FormGroup>
                                                <Grid container pl={2}>
                                                    <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                                                        <FormControlLabel
                                                            label="Acapella"
                                                            name="deliveryFormats"
                                                            value={"Acapella"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Instrumental"
                                                            name="deliveryFormats"
                                                            value={"Instrumental"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="TV/Live"
                                                            name="deliveryFormats"
                                                            value={"TV/Live"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Radio Edit"
                                                            name="deliveryFormats"
                                                            value={"Radio Edit"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column" }}>
                                                        <FormControlLabel
                                                            label="Extended Edit"
                                                            name="deliveryFormats"
                                                            value={"Extended Edit"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Clean Edit"
                                                            name="deliveryFormats"
                                                            value={"Clean Edit"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="Explicit Edit"
                                                            name="deliveryFormats"
                                                            value={"Explicit Edit"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        {/* <FormControlLabel
                                                            label="Spotify/Streaming"
                                                            name="deliveryFormats"
                                                            value={"Spotify/Streaming"}
                                                            control={<Checkbox />}
                                                            onChange={formik.handleChange}
                                                        />
                                                        <FormControlLabel
                                                            label="MFiT"
                                                            name="deliveryFormats"
                                                            value={"MFiT"}
                                                            control={<Checkbox />}
                                                            onChange={formik.handleChange}
                                                        /> */}
                                                        <FormControlLabel
                                                            label="Grouped Stems"
                                                            name="deliveryFormats"
                                                            value={"Grouped Stems"}
                                                            control={<Checkbox color="secondary" />}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Target Delivery Date *</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={formik.values.deliveryDate ?? null}
                                                    onChange={(newValue) => formik.setFieldValue('deliveryDate', newValue)}
                                                    renderInput={(params) => <TextField name="deliveryDate"
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.deliveryDate && Boolean(formik.errors.deliveryDate)}
                                                        helperText={formik.touched.deliveryDate && formik.errors.deliveryDate}
                                                        style={{ backgroundColor: "#FFFF" }} {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Is there anything else you’d like us to know about?</FormLabel>
                                            <TextField
                                                name={"extraInfo"}
                                                fullWidth sx={{ border: "1px solid #FFFF", backgroundColor: "#FFFF" }}
                                                onChange={formik.handleChange}
                                                placeholder="(ex. artist profile, link to previous releases)"
                                                multiline
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Reference Mix Link *</FormLabel>
                                            <TextField
                                                required
                                                name={"referenceMixLink"}
                                                fullWidth sx={{ border: "1px solid #FFFF", backgroundColor: "#FFFF" }}
                                                onChange={formik.handleChange}
                                                placeholder="Link to reference Track"
                                                error={formik.touched.referenceMixLink && Boolean(formik.errors.referenceMixLink)}
                                                helperText={formik.touched.referenceMixLink && formik.errors.referenceMixLink}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} pb={2}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{ paddingBottom: "4px" }}>Link Your Rough Mix Files *</FormLabel>
                                            <TextField
                                                required
                                                name={"linkToProject"}
                                                fullWidth sx={{ border: "1px solid #FFFF", backgroundColor: "#FFFF" }}
                                                onChange={formik.handleChange}
                                                placeholder="Link to rough mix (ex. Dropbox, Drive)"
                                                error={formik.touched.linkToProject && Boolean(formik.errors.linkToProject)}
                                                helperText={formik.touched.linkToProject && formik.errors.linkToProject}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} pb={2} pt={2}>
                                        <Button type="submit" fullWidth variant="contained" color="secondary">
                                            {loading && <CircularProgress color="secondary" />}
                                            {!loading && (
                                                <Typography variant="h6" color="textSecondary">
                                                    Submit
                                                </Typography>
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                    }
                </DialogContent>
            </Dialog>
        </div >
    )
}