import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { toast } from "material-react-toastify";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useMatch, useNavigate } from "react-router";
import { verifyAccountWIthJWT } from "../redux/action/auth.action";


function VerifyAccount(props) {
  const classes = useStyle();
  const [isVerified, setIsVerified] = useState(false);
  const match = useMatch("/verify-account/:token");

  const navigate = useNavigate();

  useEffect(() => {
    props
      .verifyAccountWIthJWT({
        token: match.params.token,
      })
      .then((res) => {
        console.log(res);
        setIsVerified(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  }, []);

  return (
    <Box display="flex" justifyContent="center" height="100vh">
      <Helmet>
        <title>Studio DMI | Verify Account</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Box display="flex" justifyContent="center" flexDirection="column">
        {isVerified ? (
          <>
            <Typography variant="h2" align="center">
              Success !
            </Typography>
            <br />
            <br />
            <Typography variant="h6" align="center">
              Your Account is Verified
            </Typography>
            <Box display="flex" justifyContent="center">
              <ButtonBase onClick={() => navigate("/auth")}>
                Go to Login&nbsp; <ArrowForward color="secondary" />
              </ButtonBase>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h2" align="center" color="secondary">
              Error !
            </Typography>
            <br />
            <br />
            <Typography variant="h6" align="center">
              Some error occoured
            </Typography>
            <Box display="flex" justifyContent="center">
              <ButtonBase onClick={() => navigate("/")}>
                <ArrowBack color="secondary" />
                &nbsp; Go to StudioDMI
              </ButtonBase>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    left: 0,
    padding: "20px 100px",
  },
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
}));

export default connect(null, { verifyAccountWIthJWT })(VerifyAccount);
