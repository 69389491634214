import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { startLoading } from "../../redux/action/utils.action";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  loadingdiv: {
    display: "flex",
    position: "absolute",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    zIndex: 10000,
    height: "100%",
    top: 0,
    opacity: "0.7",
  },
});

class Loading extends React.Component {
  render() {
    return (
      <>
        {this.props.loading ? (
          <div className={this.props.classes.loadingdiv}>
            <CircularProgress color="secondary" />
          </div>
        ) : null}
      </>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state.loading,
  };
};

export default connect(mapStateToProps, { startLoading })(
  withStyles(styles)(Loading)
);
