import { combineReducers } from "redux";

import feedback from "./snackbar.reducer";
import auth from "./auth.reducer";
import loading from "./startloading.reducer";
import cart from "./cart.reducer";
const reducers = combineReducers({
  feedback: feedback,
  auth: auth,
  loading: loading,
  cart: cart,
  
});

export default reducers;
