import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/icons-material/Menu";

import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileDrawer from "./drawers/profileMenu";
import CartDrawer from "./drawers/shoppingCart";
import IconCart from "./svgAssets/IconCart";
import StartAProject from "./StartAProject";

export default function Layout(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const [transparentNav, setTransparentNav] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [cartDrawer, setCartDrawer] = useState(false);
  const [profileDrawer, setProfileDrawer] = useState(false);
  // console.log(location);
  const [projectModal, setProjectModal] = useState(false)
  const token = useSelector((state) => state.auth.token);
  const cart = useSelector((state) => state.cart.items);
  const itemsInCart = Object.keys(cart).length;

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : {};

  // console.log(userData);

  const openLink = (link) => {
    navigate(link);
  };

  const toggleMenuDrawer = (isOpen) => (event) => {
    console.log(isOpen, event);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenuDrawer(isOpen);
  };
  const toggleCartDrawer = (isOpen) => (event) => {
    console.log(isOpen, event);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setCartDrawer(isOpen);
  };

  const toggleProfileDrawer = (isOpen) => (event) => {
    console.log(isOpen, event);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setProfileDrawer(isOpen);
  };

  const openProjectModal = () => {
    toggleMenuDrawer(false)
    toggleCartDrawer(false)
    setProjectModal(true)
  }

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset);
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = currentScrollPos == 0;
      setTransparentNav(visible);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const menuList = () => (
    <Box
      width="210px"
      role="menu"
      className={classes.menuDrawer}
      onClick={toggleMenuDrawer(false)}
      onKeyDown={toggleMenuDrawer(false)}
    >
      <List>
        (
        <ListItem button>
          {token || localStorage.getItem("token") ? (
            <ButtonBase
              focusRipple
              className={classes.headBtns}
              onClick={toggleProfileDrawer(true)}
            >
              <Typography variant="button" color="textSecondary">
                {userData?.name}
              </Typography>
              <KeyboardArrowDown style={{ color: "#fff" }} />
            </ButtonBase>
          ) : (
            <ButtonBase
              focusRipple
              className={classes.headBtns}
              onClick={() => openLink("/auth")}
            >
              <Typography variant="button" color="textSecondary">
                SIGN IN
              </Typography>
            </ButtonBase>
          )}
        </ListItem>
        <ListItem button>
          <ButtonBase
            onClick={() => openLink("/")}
            focusRipple
            className={classes.headBtns}
          >
            <Typography variant="button" className={classes.headText}>
              Home
            </Typography>
          </ButtonBase>
        </ListItem>
        <ListItem button>
          {/* <PopoverCars> */}
          <ButtonBase
            onClick={() => openLink("/featured-work")}
            focusRipple
            className={classes.headBtns}
          >
            <Typography variant="button" className={classes.headText}>
              featured work
            </Typography>
          </ButtonBase>
        </ListItem>
        <ListItem button>
          <ButtonBase
            focusRipple
            className={classes.headBtns}
            onClick={() => openLink("/studios")}
          >
            <Typography variant="button" className={classes.headText}>
              studios
            </Typography>
          </ButtonBase>
        </ListItem>
        <ListItem button>
          <ButtonBase
            focusRipple
            className={classes.headBtns}
            onClick={() => openLink("/edu")}
          >
            <Typography variant="button" className={classes.headText}>
              edu
            </Typography>
          </ButtonBase>
        </ListItem>
        <ListItem button>
          <ButtonBase
            focusRipple
            className={classes.headBtns}
            onClick={() => openLink("/tech")}
          >
            <Typography variant="button" className={classes.headText}>
              tech
            </Typography>
          </ButtonBase>
        </ListItem>
        {/*<ListItem button>*/}
        {/*  <ButtonBase*/}
        {/*    focusRipple*/}
        {/*    className={classes.headBtns}*/}
        {/*    onClick={() => openLink("/media")}*/}
        {/*  >*/}
        {/*    <Typography variant="button" className={classes.headText}>*/}
        {/*      Media*/}
        {/*    </Typography>*/}
        {/*  </ButtonBase>*/}
        {/*</ListItem>*/}
        <ListItem>
          <StartAProject open={projectModal} handleClickOpen={openProjectModal} setOpen={setProjectModal} >
            <div className="projectBtnDrawer">
              <div className="btn-secondary">Start your project</div>
            </div>
          </StartAProject>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <div className={classes.root}>
        {location.pathname != "/auth" &&
          location.pathname != "/reset-password" && (
            <AppBar
              position={props.position ? props.position : "fixed"}
              className={
                (location.pathname == "/" ||
                  location.pathname == "/featured-Work" ||
                  location.pathname == "/studios" ||
                  location.pathname == "/media") &&
                  transparentNav
                  ? classes.appBarHome
                  : classes.appBar
              }
              color={
                (location.pathname == "/" ||
                  location.pathname == "/featured-Work" ||
                  location.pathname == "/studios" ||
                  location.pathname == "/media") &&
                  transparentNav
                  ? "transparent"
                  : "background"
              }
              elevation={0}
            >
              <React.Fragment key="menuDrawer">
                <Drawer
                  anchor="left"
                  open={menuDrawer}
                  onClose={toggleMenuDrawer(false)}
                >
                  {menuList()}
                </Drawer>
              </React.Fragment>
              <React.Fragment key="cartDrawer">
                <Drawer
                  anchor="right"
                  open={cartDrawer}
                  onClose={toggleCartDrawer(false)}
                >
                  <CartDrawer
                    toggle={toggleCartDrawer}
                    itemsInCart={itemsInCart}
                  />
                </Drawer>
              </React.Fragment>
              <React.Fragment key="profileDrawer">
                <Drawer
                  anchor="right"
                  open={profileDrawer}
                  onClose={toggleProfileDrawer(false)}
                >
                  <ProfileDrawer toggle={toggleProfileDrawer} />
                </Drawer>
              </React.Fragment>
              <Toolbar disableGutters className={classes.toolbar}>
                <Container sx={{ display: { xs: "block", md: "none" } }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex">
                      <Typography variant="button" color="textSecondary">
                        <IconButton
                          color="secondary"
                          aria-label=""
                          onClick={toggleMenuDrawer(true)}
                        >
                          <Menu color="secondary" />
                        </IconButton>
                      </Typography>
                      &nbsp;&nbsp;
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        onClick={() => navigate("/")}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="/logo1.png"
                          style={{
                            objectFit: "cover",
                            height: "35px",
                            width: "auto",
                          }}
                        />
                      </Typography>
                    </Box>
                    {/*<Box display="flex">*/}
                    {/*  <Box*/}
                    {/*    display="flex"*/}
                    {/*    alignItems="center"*/}
                    {/*    justifyContent="flex-end"*/}
                    {/*    padding="0 5px 5px"*/}
                    {/*  >*/}
                    {/*    <IconButton*/}
                    {/*      color="secondary"*/}
                    {/*      aria-label="cart"*/}
                    {/*      onClick={toggleCartDrawer(true)}*/}
                    {/*    >*/}
                    {/*      <Badge badgeContent={itemsInCart} color="secondary">*/}
                    {/*        <IconCart color="#fff" />*/}
                    {/*      </Badge>*/}
                    {/*    </IconButton>*/}
                    {/*  </Box>*/}
                    {/*</Box>*/}
                  </Box>
                </Container>
                <Container
                  maxWidth="lg"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <Grid
                    container
                    maxWidth={"lg"}
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item md={2} xs={12} justify="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        padding="0 5px 5px"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                            onClick={() => navigate("/")}
                            style={{
                              marginRight: 32,
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <img src="/logo1.png" width="auto" height="auto" />
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item md={6} xs={9} justify="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        padding="0 5px 5px"
                      >
                        <ButtonBase
                          onClick={() => openLink("/")}
                          focusRipple
                          className={
                            location.pathname == "/"
                              ? classes.headBtnFocused
                              : classes.headBtns
                          }
                        >
                          <Typography
                            variant="button"
                            className={classes.headText}
                          >
                            Home
                          </Typography>
                        </ButtonBase>

                        {/* <PopoverCars> */}
                        <ButtonBase
                          onClick={() => openLink("/featured-Work")}
                          focusRipple
                          className={
                            location.pathname == "/featured-work"
                              ? classes.headBtnFocused
                              : classes.headBtns
                          }
                        >
                          <Typography
                            variant="button"
                            className={classes.headText}
                          >
                            featured work
                          </Typography>
                        </ButtonBase>
                        {/* </PopoverCars> */}
                        <ButtonBase
                          focusRipple
                          className={
                            location.pathname == "/studios"
                              ? classes.headBtnFocused
                              : classes.headBtns
                          }
                          onClick={() => openLink("/studios")}
                        >
                          <Typography
                            variant="button"
                            className={classes.headText}
                          >
                            studios
                          </Typography>
                        </ButtonBase>
                        <ButtonBase
                          focusRipple
                          className={
                            location.pathname == "/edu"
                              ? classes.headBtnFocused
                              : classes.headBtns
                          }
                          onClick={() => openLink("/edu")}
                        >
                          <Typography
                            variant="button"
                            className={classes.headText}
                          >
                            edu
                          </Typography>
                        </ButtonBase>
                        <ButtonBase
                          focusRipple
                          className={
                            location.pathname == "/tech"
                              ? classes.headBtnFocused
                              : classes.headBtns
                          }
                          onClick={() => openLink("/tech")}
                        >
                          <Typography
                            variant="button"
                            className={classes.headText}
                          >
                            tech
                          </Typography>
                        </ButtonBase>

                        {/*<ButtonBase*/}
                        {/*  focusRipple*/}
                        {/*  className={classes.headBtns}*/}
                        {/*  onClick={() => openLink("/media")}*/}
                        {/*>*/}
                        {/*  <Typography*/}
                        {/*    variant="button"*/}
                        {/*    className={classes.headText}*/}
                        {/*  >*/}
                        {/*    Media*/}
                        {/*  </Typography>*/}
                        {/*</ButtonBase>*/}

                        {/* {!login ? (
                    <LoginModal>
                      {" "}
                      <Button variant="contained" color="primary">
                        Login/Register
                      </Button>
                    </LoginModal>
                  ) : (
                    <ButtonBase
                      onClick={handleClick}
                      style={{ fontColor: "#000", display: "flex" }}
                    >
                      <Typography style={{ width: "100%" }} color="primary">
                        {userData.name?.split(" ")[0]}
                      </Typography>
                      <ExpandMoreIcon color={"primary"} />
                    </ButtonBase>
                  )} */}
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="0 5px 5px"
                      >
                        {/*<Typography variant="button" color="textPrimary">*/}
                        {/*  <IconButton*/}
                        {/*    aria-label=""*/}
                        {/*    onClick={toggleCartDrawer(true)}*/}
                        {/*  >*/}
                        {/*    <Box display="flex" position="relative">*/}
                        {/*      <IconCart color="#fff" />*/}
                        {/*      {itemsInCart > 0 && (*/}
                        {/*        <Box*/}
                        {/*          display="flex"*/}
                        {/*          flexDirection="column"*/}
                        {/*          className={classes.cartItemNo}*/}
                        {/*          justifyContent="center"*/}
                        {/*        >*/}
                        {/*          <Typography*/}
                        {/*            align="center"*/}
                        {/*            variant="caption"*/}
                        {/*            color="textSecondary"*/}
                        {/*          >*/}
                        {/*            {itemsInCart}*/}
                        {/*          </Typography>*/}
                        {/*        </Box>*/}
                        {/*      )}*/}
                        {/*    </Box>*/}
                        {/*  </IconButton>*/}
                        {/*</Typography>*/}
                        &nbsp;&nbsp;&nbsp;
                        {token || localStorage.getItem("token") ? (
                          <ButtonBase
                            focusRipple
                            className={classes.headBtns}
                            onClick={toggleProfileDrawer(true)}
                          >
                            <Typography variant="button" color="textSecondary">
                              {userData?.name}
                            </Typography>
                            <KeyboardArrowDown style={{ color: "#fff" }} />
                          </ButtonBase>
                        ) : (
                          <ButtonBase
                            focusRipple
                            className={classes.headBtns}
                            onClick={() => openLink("/auth")}
                          >
                            <Typography variant="button" color="textSecondary">
                              SIGN IN
                            </Typography>
                          </ButtonBase>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={0}>
                      {(location.pathname == "/" ||
                        location.pathname == "/featured-Work" ||
                        location.pathname == "/studios") && (
                          <StartAProject
                            open={projectModal} handleClickOpen={openProjectModal} setOpen={setProjectModal}
                          >
                            <Box display={"flex"} justifyContent={"flex-end"}>
                              <div className="projectBtn">
                                <div className="btn-secondary">
                                  Start your project
                                </div>
                              </div>
                            </Box>
                          </StartAProject>
                        )}{" "}
                    </Grid>
                  </Grid>
                </Container>
              </Toolbar>
            </AppBar>
          )}

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          {props.children}
        </main>
        <div className={classes.footer}>
          {/* <SEOLinks />
        <Footer /> */}
        </div>
      </div>
    </Container>
  );
}

const useStyle = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  shown: {
    display: "flex",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 111,
    width: "100%",
    overflowX: "hidden",
  },
  headText: {
    padding: "0 10px",
    color: "#AAC9DD",
  },
  headTextFocused: {
    color: "#111111",
    padding: "0 10px",
  },
  headBtns: {
    "&:focus": {
      "& $headText": {
        backgroundColor: "#ffffff",
        color: "#111111",
      },
    },
  },
  headBtnFocused: {
    "& $headText": {
      backgroundColor: "#ffffff",
      color: "#111111",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between", // keep right padding when drawer closed
  },
  toolbarTop: {
    height: "28px",
    backgroundColor: "#F5F5F6",
    display: "flex",
    fontSize: 14,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  logo: {
    height: 42,
    objectFit: "contain",
    display: "flex",
    justifyContent: "flex-end",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  menuDrawer: {
    backgroundColor: theme.palette.background.main,
    boxShadow: "0px 1px 56px #F3F0FB",
    height: "100%",
  },
  cartDrawer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 1px 56px #F3F0FB",
    height: "100%",
  },
  appBar: {
    backgroundColor: theme.palette.background.main,
    boxShadow: "0px 1px 56px #F3F0FB",
    width: "100%",
  },
  appBarHome: {
    backgroundColor: "transparent",
    boxShadow: "0px 1px 56px #F3F0FB",
    width: "100%",
    zIndex: 251,
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      marginRight: 36,
    },
  },

  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    marginTop: -64,
  },
  footer: {
    flexGrow: 1,
    overflow: "hidden",
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  toolbarButton: {
    marginRight: 32,
  },
  bio: {
    width: "100%",
    padding: "12px 1rem",
  },
  bioProfile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stat: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.2rem 1rem 0.5rem 1rem",
    alignItems: "center",
  },

  statItem: {
    textAlign: "center",
  },
  toolbarSearch: {
    background: "#F5F5F6",
    borderRadius: "10px",
    fontSize: "12px",
    marginLeft: 16,
    minWidth: 200,
    paddingLeft: 16,
    paddingRight: 16,
    height: 40,
  },
  search: {
    border: "1px solid",
    borderColor: theme.palette.divider,
    outline: "none",
    color: theme.palette.text.primary,
    padding: 5,
    fontSize: 15,
    marginLeft: 16,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    "& img": {
      borderRadius: "50%",
      padding: 2,
      background: theme.palette.background.default,
    },
    background: theme.palette.primary.main,
    padding: 2,
  },

  highlighted: {
    background: theme.palette.background.default,
  },
  customRemoveIcon: {
    border: "1px solid #000",
    borderRadius: "25px",
    color: "#000",
  },
  redCustomBtn: {
    backgroundColor: theme.palette.secondary.main,
  },
  profileDrawer: {
    padding: "30px",
  },
  cartItemNo: {
    position: "absolute",
    top: 0,
    left: 10,
    borderRadius: "25px",
    width: "14px",
    height: "14px",
    backgroundColor: theme.palette.secondary.main,
  },
}));
