import { useState } from 'react';
import './lightbox.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { FreeMode, Navigation, Thumbs } from "swiper";


import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";

export default function Lightbox(props) {
    const { images } = props
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {
                    images.map((item, index) => (
                        <SwiperSlide>
                            <img src={item} />
                        </SwiperSlide>
                    ))
                }

            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {
                    images.map((item, index) => (
                        <SwiperSlide>
                            <img src={item} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    )
}