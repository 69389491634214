import axios from "../../utils/axios";
import api from "../../utils/api";

export const logout = () => (dispatch) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  localStorage.clear();
  axios.defaults.headers.common.Authorization = null;
  dispatch({
    type: "LOGOUT",
  });
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: false,
    },
  });
};

export const login = (data) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${api.userLogin}`, data)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        axios.defaults.headers.common.Authorization = response.data.token;
        console.log(response.data);
        console.log(axios.defaults.headers.common.Authorization);
        dispatch({
          type: "LOGIN_USER",
          payload: { ...response.data },
        });
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "Login successfully",
            type: "success",
          },
        });

        resolve(response.data);
      })
      .catch((err) => {
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          case 423:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Your account has been blocked! Please contact admin.",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Login failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });

export const createAdminUser = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.createAdmin}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "User created successfully",
            type: "success",
          },
        });

        resolve(response.data);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const getAdminUsers = () => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.fetchAdminUsers}`)
      .then((response) => {
        resolve(response.data.ServerResponse);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "fetch failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const signup = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.createUser}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "User created successfully",
            type: "success",
          },
        });
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });

        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Add User Process failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const verifyAccountWIthJWT = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.verifyAccount}`, data)
      .then((response) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "Password Changed successfully",
            type: "success",
          },
        });

        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 400:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid or expired token!",
                type: "error",
              },
            });
            break;
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;

          case 409:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Password can't be same as previous one!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const createManyUsers = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.createManyUsers}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "Users added successfully",
            type: "success",
          },
        });
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Add User Process failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const forgotPasswordRequest = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.forgotPassRequest}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "Mail Sent successfully",
            type: "success",
          },
        });
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Email Id not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const resetPassword = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.resetPassword}`, data)
      .then((response) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "Password Changed successfully",
            type: "success",
          },
        });

        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 400:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid or expired token!",
                type: "error",
              },
            });
            break;
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;

          case 409:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Password can't be same as previous one!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const updatePassword = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.updatePassword}`, data)
      .then((response) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "Password Changed successfully",
            type: "success",
          },
        });

        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 400:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid or expired token!",
                type: "error",
              },
            });
            break;
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;

          case 409:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Password can't be same as previous one!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const deleteAccount = (userId) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .delete(`user/${userId}`)
      .then((response) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "User deltetd successfully",
            type: "success",
          },
        });

        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 400:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid or expired token!",
                type: "error",
              },
            });
            break;
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;

          case 409:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Password can't be same as previous one!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const updateUser = (data, userId) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .patch(`${api.updateUser}${userId}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_SNACKBAR",
          payload: {
            snackbar: true,
            message: "User data updated successfully",
            type: "success",
          },
        });
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        resolve(response.data);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "update failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const getUser = (id) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.fetchUsers}_id=${id}`)
      .then((response) => {
        resolve(response.data.ServerResponse[0]);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "fetch failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const getAllUsers = () => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.fetchUsers}`)
      .then((response) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        resolve(response.data.ServerResponse);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "fetch failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};

export const getAllUserOptions = () => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.fetchUserOptions}`)
      .then((response) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        resolve(response.data.ServerResponse);
      })
      .catch((err) => {
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
        console.log(err.response.status);
        switch (err.response.status) {
          case 401:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "Invalid credentials",
                type: "error",
              },
            });
            break;
          case 404:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "User not found!",
                type: "error",
              },
            });
            break;
          default:
            dispatch({
              type: "UPDATE_SNACKBAR",
              payload: {
                snackbar: true,
                message: "fetch failed",
                type: "error",
              },
            });
            break;
        }
        reject(err);
      });
  });
};
