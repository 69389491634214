import Remove from "@mui/icons-material/Remove";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { removeCartItem } from "../../redux/action/cart.action";
import IconCart from "../svgAssets/IconCart";
export default function CartDrawer(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.items);

  return (
    <Box
      padding="0 20px"
      width="320px"
      sx={{ width: { xs: "320px", sm: "350px" } }}
      role="shoppingCart"
      className={classes.cartDrawer}
      // onClick={props.toggle(false)}
      onKeyDown={props.toggle(false)}
    >
      <List>
        <ListItem key="cartHead">
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="initial">
              SHOPPING CART
            </Typography>

            <IconButton color="primary" onClick={props.toggle(false)}>
              <Box display="flex" position="relative">
                <IconCart color="primary" />
                {props.itemsInCart > 0 && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.cartItemNo}
                    justifyContent="center"
                  >
                    <Typography
                      align="center"
                      variant="caption"
                      color="textSecondary"
                    >
                      {props.itemsInCart}
                    </Typography>
                  </Box>
                )}
              </Box>
            </IconButton>
          </Box>
        </ListItem>
        {Object.keys(cart).map((productId, index) => (
          <>
            {index != 0 && (
              <>
                <br />
                <Divider />
              </>
            )}
            <br />
            <ListItem key={productId}>
              <Box display="flex" flexDirection="column">
                {/* <img src="/cartItem1.png" width="100%" /> */}
                <Typography
                  variant="h6"
                  color="initial"
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {cart[productId].name}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" color="initial">
                      PRICE :
                    </Typography>
                    &nbsp; &nbsp;
                    <Typography variant="h4" color="secondary">
                      $ {cart[productId].price}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => dispatch(removeCartItem(productId))}
                  >
                    <Remove className={classes.customRemoveIcon} />
                  </IconButton>
                </Box>
              </Box>
            </ListItem>
          </>
        ))}
        {/* 
        <ListItem>
          <Box display="flex" flexDirection="column">
            <img src="/cartItem1.png" width="100%" />
            <Typography
              variant="h6"
              color="initial"
              style={{ fontWeight: 500, textTransform: "uppercase" }}
            >
              "Mix-Master Series" Season 2 Episode 6: Deep & Tech House Mixing
              SPECIAL GUEST: DJ CARNAGE aka GORDO
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignContent="center"
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6" color="initial">
                  PRICE :
                </Typography>
                &nbsp; &nbsp;
                <Typography variant="h4" color="secondary">
                  75$
                </Typography>
              </Box>
              <IconButton>
                <Remove className={classes.customRemoveIcon} />
              </IconButton>
            </Box>
            <br />
          </Box>
        </ListItem> */}
        <ListItem>
          {Object.keys(cart).length ? (
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              component={Link}
              to="/payment"
              onClick={props.toggle(false)}
            >
              BUY
            </Button>
          ) : (
            <div
              style={{
                width: "100%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ProductionQuantityLimitsIcon style={{ fontSize: 80 }} />
              <Typography variant="h5" align="center">
                No items in cart
              </Typography>
            </div>
          )}
        </ListItem>
      </List>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  profileDrawer: {
    padding: "30px",
  },
  menuItem: {
    width: "100%",
  },
  greyDivider: {
    color: "#dbdbdb",
  },
  signout: {
    paddingLeft: "16px",
  },
  cartItemNo: {
    position: "absolute",
    top: 0,
    left: 10,
    borderRadius: "25px",
    width: "14px",
    height: "14px",
    backgroundColor: theme.palette.secondary.main,
  },
}));
