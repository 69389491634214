const INIT_STATE = {
  items: {}, //success info warning error
  //text
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_CART":
      return {
        ...state,
        items: action.payload,
      };

    default:
      return state;
  }
};
