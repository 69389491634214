import { createContext, useContext, useEffect, useState } from "react";
import { EventEmitter } from "../components/common/EventEmitter";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState({});
  const [itemsInCart, setItemsInCart] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("cart")) {
      let newCart = JSON.parse(localStorage.getItem("cart"));
      setCart(newCart);
      EventEmitter.dispatch("cartUpdate", newCart);
    }
    // localStorage.setItem("cart", JSON.stringify({}));
  }, []);

  function addCartItem(newItem) {
    let newCart = cart;
    newCart[newItem._id] = newItem;
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    EventEmitter.dispatch("cartUpdate", newCart);
  }

  function removeCartItem(itemId) {
    let newCart = cart;
    delete newCart[itemId];
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    EventEmitter.dispatch("cartUpdate", newCart);
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        itemsInCart,
        addCartItem,
        removeCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
