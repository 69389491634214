import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const LineWaveGray = (props) => {
  return (
    <SvgIcon
      width="726"
      height="20"
      viewBox="0 0 726 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_184_291)">
        <path
          d="M4 6.5C4 6.5 250 6.5 293.5 6.5C296.825 6.5 295.5 1 300 1C304.5 1 303.461 11.5 308.5 11.5C312.5 11.5 313.479 6.5 317.5 6.5C321 6.5 323.5 6.5 323.5 6.5H1284.5"
          stroke="#212529"
          stroke-opacity="0.15"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_184_291"
          x="0"
          y="0.5"
          width="1288.5"
          height="19.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_184_291"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_184_291"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};
export default LineWaveGray;
