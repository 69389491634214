import axios from "axios";
import store from "../redux/store";

// Next we make an 'instance' of it

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common.Authorization = localStorage.getItem("token");

// Also add/ configure interceptors && all the other cool stuff
const isHandlerEnabled = (config = {}) =>
  !(config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled);

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // Handle error
    // store.dispatch({ type:"UPDATE_SNACKBAR", payload: {loading:false,snackbar:true,message:"Oops caught some error! Please try again",severity:"error"} })
  }
  return Promise.reject({ ...error });
};

const errorHandlerResponse = (error) => {
  if (isHandlerEnabled(error.config)) {
    if (!error.response || !error.response.status) {
      store.dispatch({
        type: "UPDATE_SNACKBAR",
        payload: {
          loading: false,
          snackbar: true,
          message: "Network error,Please try again",
          severity: "error",
        },
      });
      error.response = { status: "network" };
    }

    // Handle errors
    if (error.response.status == 401) {
      store.dispatch({
        type: "UPDATE_SNACKBAR",
        payload: {
          loading: false,
          snackbar: true,
          message: "Session timeout,Please login again",
          severity: "info",
        },
      });
      localStorage.removeItem("token");
      store.dispatch({ type: "LOGOUT_USER" });
      setTimeout(() => (window.location = "/auth"), 200);
    }

    if (error.response.status == 500) {
      store.dispatch({
        type: "UPDATE_SNACKBAR",
        payload: {
          loading: false,
          snackbar: true,
          message: "Server error,Please try again",
          severity: "error",
        },
      });
    }
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  return response;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandlerResponse(error)
);

export default instance;
