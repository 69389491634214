import axios from "../../utils/axios";
import api from "../../utils/api";

export const updateFeedBack = (payload) => (dispatch) => {
  dispatch({ type: "UPDATE_SNACKBAR", payload: payload });
};

export const startLoading = (payload) => (dispatch) => {
  dispatch({ type: "START_LOADING", payload: payload });
};

export const contactUs = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api.contact}`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const startAProject = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api.startProject}`, data)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
