// import logo from "./logo.svg";

import { Button, Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { helmetJsonLdProp } from "react-schemaorg";
import { useConfirmationDialogContext } from "../components/common/ConfirmationDialog";
import { addCartItem } from "../redux/action/cart.action";
import styled from "@emotion/styled";


const InfoDiv = styled("div")(({ theme }) => ({
  bottom: 0,
  padding: "2rem",
  backgroundColor: "rgb(0,0,0, 0.6)",
  color: "#FFFF",
  position: "relative",
  [theme.breakpoints.up("md")]: { position: "absolute" },
}));
function Media(props) {
  const classes = useStyle();

  const dialogContext = useConfirmationDialogContext();
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(addCartItem({
      courseName: "ADE Live workshop, The Abbey Road Institute, Amsterdam, NL, Saturday, 22/10/2022 ",
      name: "ADE Live workshop, 22/10/2022",
      price: Number(process.env.REACT_APP_WORKSHOP_PRICE),
      type: "workshop"
    }))
  }
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@type": "Event",
            "about": "Live Workshop by Luca Petrolesi, The Abbey Road Institute, Amsterdam, NL",
            "keywords": "Mixing and Mastering, Audio Mixing, Luca Petrolesi, StudioDMI",
            "location": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Amsterdam, NL",
                "addressRegion": "UK",
                "streetAddress": "The Abbey Road Institute"
              },
              "name": "The Abbey Road Institute"
            },
            "name": "ADE Live Workshop",
            "offers": {
              "@type": "Offer",
              "price": `"${process.env.REACT_APP_WORKSHOP_PRICE}"`,
              "priceCurrency": "USD",
              "url": "http://www.studiodmi.com/media"
            },
            "performer": {
              "@type": "Person",
              "name": "Luca Petrolesi",
            },
            "organizer": {
              "@type": "Organization",
              "name": "StudioDMI",
              "url": "https://www.studiodmi.com"
            },
            "duration": "PT4H",
            "startDate": "2022-10-22T01:00:00",
            "endDate": "2022-10-22T05:00:00",
            "image": "https://www.studiodmi.com/workshop1.webp"
          })
        ]}
      >
        <title>ADE Live Workshop, The Abbey Road Institute, Amsterdam, NL | Media - Studio DMI</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div style={{ backgroundColor: "black", height: "100vh" }}>
      </div>
      {/* <div style={{ backgroundColor: "black" }}>
        <div style={{ background: "url(/images/sales-deck/mediabg.jpg)", backgroundBlendMode: 'overlay', backgroundColor: '#08080866' }}>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 60 }}>
            <img height={200} width={'auto'} src="/logo-lg.png" />
          </div>
          <Grid container style={{ marginTop: 10, display: "flex", justifyContent: 'flex-end' }}>

            <Grid item xs={12} md={8} position={'relative'}>
              <img
                src="/images/sales-deck/Picture3.jpg"
                width={"100%"}
                style={{ objectFit: "contain" }}
              />
              <InfoDiv>
                <Typography variant="body1" style={{ color: "#FFFF" }}>
                  STUDIO DMI has become one of the world’s premier mixing and mastering studios specializing in Dance, Pop and Hip Hop with credits such as Skrillex, Drake and Diplo to name a few of our consistent clients. Led by renowned award winning Engineer Luca Pretolesi, STUDIO DMI was established in 2012 and is constantly evolving, providing exceptional work with our unrivaled signature style and sound.
                </Typography>
              </InfoDiv>
            </Grid>
            
            <Grid item xs={12} md={8} position={'relative'}>
              <img src="/images/sales-deck/Picture4.jpg" />
              <InfoDiv>
                <Typography variant='body1' style={{ color: "#FFFF" }}>
                  Our Studio Services include Stereo Mastering, Stem Mixing and Mastering on an individual basis or packaged as Full Mix and Masters. STUDIO DMI is also a partner studio of Dolby and offers one of the top Atmos studios in the world as proclaimed by Mix Magazine. With projects such as Skrillex’s Quest For Fire, Daft Punk’s 10 year Anniversary Edition of Random Access Memories and many more, STUDIO DMI is everything post production.
                </Typography>
              </InfoDiv>
            </Grid>
           
          </Grid>
        </div>
        <div style={{ position: "relative", width: "100%" }}>
          <img src="/images/sales-deck/Picture5.jpg" width={"100%"} />
          <div style={{ position: "absolute", top: 10, padding: "2rem", color: "#FFFF" }}>
            <Typography variant="h6" style={{ color: "#FFFF", textDecoration: 'underline' }}>
              <a href="https://music.apple.com/us/playlist/luca-pretolesi-the-engineers/pl.cef666c2a6ec45f0816e5aef877b32a0" target="_blank" style={{ color: "#FFFF" }}>LUCA PRETOLESI ARTIST PROFILE DOLBY ARTIST FEATURE</a>
            </Typography>
            <Typography variant="h6" style={{ color: "#FFFF", textDecoration: 'underline' }}>
              <a href="https://music.apple.com/us/playlist/robert-guzman-the-engineers/pl.b83002aaf4b14dd3a68f9a2261fc9ba9" target="_blank" style={{ color: "#FFFF" }}>ROBERT GUZMAN ARTIST PROFILE DOLBY ARTIST FEATURE</a>
            </Typography>
            <Typography variant="h6" style={{ color: "#FFFF", textDecoration: 'underline' }}>
              <a href="https://youtu.be/WKpNO3yxpv4?si=-G7jrKlC4GSulCIg" target="_blank" style={{ color: "#FFFF" }}>STUDIO DMI ATMOS PLAYLIST</a>
            </Typography>
            <br />
            <Typography variant="h6" style={{ color: "#FFFF" }}>www.studiodmi.com</Typography>
            <Typography variant="h6" style={{ color: "#FFFF" }}>@studiodmi</Typography>
            <Typography variant="h6" style={{ color: "#FFFF" }}>STUDIO DMI</Typography>
            <br />
            <Typography variant="h6" style={{ color: "#FFFF" }}>6389 Ponderosa Way <br /> Las Vegas, NV 89118</Typography>
            <Typography variant="h6" style={{ color: "#FFFF" }}>Contact | Torie Torres <br /> 702.860.6180</Typography>
          </div>
        </div>
        <br />
        <br />
        {" "}
      </div>
      <Box
        display="flex"
        className={classes.footerDiv}
        justifyContent="space-between"
        alignItems={"center"}
        padding="0 40px"
      >
        <Typography variant="subtitle1" color="text.secondary">
          Studio DMI
          <Typography
            component={"span"}
            variant="caption"
            color="text.secondary"
          >
            {" "}
            © {new Date().getFullYear()}
          </Typography>
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Powered by @StudioDMI
        </Typography>
      </Box> */}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    // height: "100vh",
  },
  headerImg: {
    height: "340px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  imageBtn: {
    cursor: "pointer",
  },
  verticalDivider: {
    height: "auto",
    width: "2px",
    color: "#dbdbdb",
  },
  greyDivider: {
    color: "#dbdbdb",
  },
  textBtn: {
    color: theme.palette.secondary.main,
  },
  historyIndex: {
    width: "100%",
    position: "absolute",
    top: 5,
    left: 0,
  },
  dateBox: {
    backgroundColor: "#AAC9DD",
    borderRadius: "44px",
    padding: "5px 0",
  },
  footerDiv: {
    backgroundColor: "#0A0B0C",
    height: 80,
  },
}));

export default Media;
