import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const IconVideo = (props) => {
  return (
    <SvgIcon
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 0H1.46875C0.65625 0 0 0.6875 0 1.5V10.5312C0 11.3438 0.65625 12 1.46875 12H10.5C11.3125 12 12 11.3438 12 10.5312V1.5C12 0.6875 11.3125 0 10.5 0ZM16.4062 1.1875L13 3.5625V8.46875L16.4062 10.8438C17.0625 11.2812 18 10.8125 18 10.0312V2C18 1.21875 17.0625 0.75 16.4062 1.1875Z"
        fill="#AAC9DD"
      />
    </SvgIcon>
  );
};
export default IconVideo;
