import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const IconCircle = (props) => {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18" cy="18" r="17.5" stroke="#E2211C" />
      <text></text>
    </SvgIcon>
  );
};
export default IconCircle;
