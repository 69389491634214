import axios from "../../utils/axios";
import urls from "../../utils/api";

export const showProduct = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(`${urls.showProduct}${id}`)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}