import * as React from "react";
import { useDispatch } from "react-redux";
import {
  AchPay, GiftCardInput, GooglePay, SquarePaymentsForm
} from "react-square-web-payments-sdk";
import { squareOrder } from "../../redux/action/order.action";
const MyPaymentForm = ({ items, total, userName, onSuccess }) => {
  const dispatch = useDispatch();
  return (
    <>
      <SquarePaymentsForm
        /**
         * Identifies the calling form with a verified application ID
         * generated from the Square Application Dashboard.
         */
        applicationId={process.env.REACT_APP_SQAURE_APPLICATION_ID}
        /**
         * Invoked when payment form receives the result of a tokenize generation request.
         * The result will be a valid credit card or wallet token, or an error.
         */
        cardTokenizeResponseReceived={(token, buyer) => {
          dispatch(squareOrder({ id: token.token, items: items }))
            .then((res) => onSuccess())
            .catch((err) => console.log(err));
        }}
        /**
         * This function enable the Strong Customer Authentication (SCA) flow
         *
         * We strongly recommend use this function to verify the buyer and
         * reduce the chance of fraudulent transactions.
         */
        // createVerificationDetails={() => ({
        //   amount: "1.00",
        //   /* collected from the buyer */
        //   billingContact: {
        //     addressLines: ["123 Main Street", "Apartment 1"],
        //     familyName: "Doe",
        //     givenName: "John",
        //     countryCode: "GB",
        //     city: "London",
        //   },
        //   currencyCode: "GBP",
        //   intent: "CHARGE",
        // })}
        createPaymentRequest={() => ({
          countryCode: "US",
          currencyCode: "USD",
          total: { amount: `${total}`, label: "Total" },
        })}
        /**
         * Identifies the location of the merchant that is taking the payment.
         * Obtained from the Square Application Dashboard - Locations tab.
         */
        locationId={`${process.env.REACT_APP_SQUARE_LOCATION_ID}`}
      >
        <GooglePay />
        <br />

        <AchPay
          accountHolderName={userName}
          overrideStyles={{ backgroundColor: "#1A1A30" }}
        />
        <br />
      </SquarePaymentsForm>
      <br />
      <br />
      <SquarePaymentsForm
        /**
         * Identifies the calling form with a verified application ID
         * generated from the Square Application Dashboard.
         */
        applicationId="sandbox-sq0idb-xNh9aMS2DUUmxvYaG3a5_g"
        /**
         * Invoked when payment form receives the result of a tokenize generation request.
         * The result will be a valid credit card or wallet token, or an error.
         */
        cardTokenizeResponseReceived={(token, buyer) => {
          dispatch(squareOrder({ id: token.token, items: items }))
            .then((res) => onSuccess())
            .catch((err) => console.log(err));
        }}
        /**
         * This function enable the Strong Customer Authentication (SCA) flow
         *
         * We strongly recommend use this function to verify the buyer and
         * reduce the chance of fraudulent transactions.
         */
        // createVerificationDetails={() => ({
        //   amount: "1.00",
        //   /* collected from the buyer */
        //   billingContact: {
        //     addressLines: ["123 Main Street", "Apartment 1"],
        //     familyName: "Doe",
        //     givenName: "John",
        //     countryCode: "GB",
        //     city: "London",
        //   },
        //   currencyCode: "GBP",
        //   intent: "CHARGE",
        // })}
        createPaymentRequest={() => ({
          countryCode: "US",
          currencyCode: "USD",
          total: { amount: `${total}`, label: "Total" },
        })}
        /**
         * Identifies the location of the merchant that is taking the payment.
         * Obtained from the Square Application Dashboard - Locations tab.
         */
        locationId={`${process.env.REACT_APP_SQUARE_LOCATION_ID}`}
      >
        <GiftCardInput overrideStyles={{ backgroundColor: "#1A1A30" }} />
      </SquarePaymentsForm>
    </>
  );
};

export default MyPaymentForm;
