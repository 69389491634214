import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import React, { useEffect, useState } from "react";
export default function Image(props) {
  const [url, setUrl] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${
          props?.link?.split("https://vimeo.com/")[1]
        }`
      )
      .then((res) => {
        console.log(res.data);
        setUrl(
          props.withPlay
            ? res.data.thumbnail_url_with_play_button
            : res.data.thumbnail_url
        );
      });
  }, [props.link]);

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.divStyle,
      }}
    >
      {!props.withPlay && (
        <LockIcon
          color={"secondary"}
          style={{
            position: "absolute",
            fontSize: props.lockSize ? props.lockSize : 48,
            opacity: 0.7,
          }}
        />
      )}
      <img src={url} {...props} />
    </div>
  );
}
