import axios from "../../utils/axios";
import api from "../../utils/api";

export const applyCoupon = (data, coupon) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.applyCoupon}`, { items: data, coupon })
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};

export const createOrder = (data,coupon) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.createOrder}`, { items: data, coupon })
      .then((response) => {
        resolve(response.data.id);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};

export const approveOrder = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.approveOrder}`, { id: data })
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};

export const squareOrder = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.squareOrder}`, data)
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};
export const myOrder = (data) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.myOrder}`)
      .then((response) => {
        resolve(response.data);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};
