// import logo from "./logo.svg";
import ArrowBack from "@mui/icons-material/ArrowBack";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { signup } from "../../redux/action/auth.action";
import PasswordField from "../PasswordField";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm your password")
    .oneOf([yup.ref("password")], "Passwords does not match"),
});

function SignUp(props) {
  const classes = useStyle();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verifyScreen, setVerifyScreen] = useState(false);
  const handleSignup = (values) => {
    setLoading(true);
    props
      .signup({
        name: values.name,
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        setVerifyScreen(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleSignup(values);
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      height="100%"
    >
      <Box display="flex" className={classes.backButton}>
        <ButtonBase onClick={() => navigate("/")}>
          <ArrowBack />
          &nbsp; Back
        </ButtonBase>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
      >
        <Box display="flex" justifyContent="center" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            maxWidth="350px"
            paddingLeft="24px"
            paddingRight="24px"
          >
            <Typography align="center" variant="h3" color="initial">
              SIGN UP
            </Typography>
            <br />
            {verifyScreen ? (
              <center>
                <MarkEmailReadIcon style={{ fontSize: 120 }} />
                <Typography gutterBottom variant="h5">
                  Please verify your account.
                </Typography>
                <Typography variant="body1">
                  A verification email has between sent to your email, please
                  verify your account.
                </Typography>
              </center>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" color="initial" gutterBottom>
                    Your name
                  </Typography>
                  <TextField
                    id="name"
                    placeholder="Ex.Daniel"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <br />
                  <Typography variant="body1" color="initial">
                    Email address
                  </Typography>
                  <TextField
                    id="email"
                    placeholder="Ex.Lorem@gmail.com"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <br />
                  <Typography variant="body1" color="initial">
                    Password
                  </Typography>
                  <PasswordField
                    id="password"
                    placeholder="Password"
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <br />
                  <Typography variant="body1" color="initial">
                    Confirm Password
                  </Typography>
                  <PasswordField
                    id="confirmPassword"
                    placeholder="Password"
                    variant="outlined"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />

                  <br />
                  <Button variant="contained" color="primary" type="submit">
                    {loading && <CircularProgress color="secondary" />}
                    {!loading && (
                      <Typography variant="h6" color="textSecondary">
                        SIGN UP
                      </Typography>
                    )}
                  </Button>
                </Box>
              </form>
            )}

            <br />
            <Box display="flex" justifyContent="center">
              <Typography variant="body1">Already registered?</Typography>
              &nbsp;
              <ButtonBase onClick={props.login}>
                <Typography
                  variant="body1"
                  color="button"
                  className={classes.textBtn}
                >
                  Sign In
                </Typography>
              </ButtonBase>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="body1">Forgot password? </Typography>
              &nbsp;
              <ButtonBase onClick={props.forgotPass}>
                <Typography
                  variant="body1"
                  color="button"
                  className={classes.textBtn}
                >
                  Reset password
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  textBtn: {
    color: "#AAC9DD",
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "50px",
  },
}));

export default connect(null, { signup })(SignUp);
