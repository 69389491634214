import { Button, Grid, Rating, TextField, Typography } from "@mui/material";
import { toast } from "material-react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Lightbox from "../../components/lightbox/lightbox";
import { addCartItem } from "../../redux/action/cart.action";
import { showProduct } from "../../redux/action/product.action";

export default function Product(props) {
    const [product, setProduct] = useState(null)
    const [qty, setQty] = useState(1)
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.items);
console.log({cart})
    const { id } = useParams()

    useEffect(() => {
        showProduct(id)
            .then(productData => {
                setProduct(productData)
            })
            .catch(err => {
                toast.error("Product not found!")
            })
    }, [])

    const handleAddCartItem = (item) => {
        let alreadyExist = Object.values(cart).filter(
            (i) => i.courseId == item._id &&  ["bundle","product"].includes(i.type)
        );

        if (alreadyExist.length) {
            toast.info(
                `You already have a bundle (${item.courseName}) containing this chapter in your cart!`
            );
            return;
        }

        dispatch(addCartItem({ ...item, _id: item._id, courseId: item._id, type: "product" }));
    };


    return (

        product !== null ?
            <>
                <div style={{ padding: 12 }}>
                    <Grid container spacing={4} style={{ marginTop: 80, justifyContent: 'center' }}>
                        <Grid item xs={12} md={10}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component={"h1"}>
                                    {product.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" component={"p"}>
                                    {product.shortDescription}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component={"h5"}>
                                    {product.sku}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={8}>
                                        <Lightbox images={product?.images} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div style={{ display: "flex" }}>
                                            <Rating name="half-rating" defaultValue={4.5} precision={0.5} />
                                            <Typography variant="body1">&nbsp;(4)</Typography>
                                        </div>
                                        <Typography variant="h4" fontWeight={"bold"} textTransform={"uppercase"} paddingTop={4}>
                                            ${product.price}
                                        </Typography>
                                        <Typography variant="h6" fontWeight={"bold"} textTransform={"uppercase"} paddingTop={4}>
                                            Special Order
                                        </Typography>
                                        <Typography variant="body1">
                                            Note: This item may not be returnable or could require a restocking fee. Contact one of our Audio Consultants for more details.
                                        </Typography>

                                        <Typography variant="h6" fontWeight={"bold"} textTransform={"uppercase"} paddingTop={4} paddingBottom={2}>
                                            QTY: <TextField style={{ width: 60 }} type="number" value={qty ?? 1} onChange={(e) => setQty(e.target.value)} ></TextField>
                                        </Typography>
                                        <Button color="secondary" variant="contained" style={{ width: "50%" }}
                                        onClick={()=>handleAddCartItem(product)}
                                        >
                                            Buy
                                        </Button>
                                        <a href="mailto:admin@studiodmi.com"><Button variant="contained" color="primary" style={{ width: "50%" }}>
                                            Questions ?
                                        </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" paddingTop={"40px"}>
                                    PRODUCT DESCRIPTION
                                </Typography>
                                <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div >
            </>
            : <></>

    )
}