// import logo from "./logo.svg";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useMatch, useNavigate } from "react-router";
import * as yup from "yup";
import PasswordField from "../components/PasswordField";
import { resetPassword } from "../redux/action/auth.action";




const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm your password")
    .oneOf([yup.ref("password")], "Passwords does not match"),
});

function ResetPassword(props) {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);

  const match = useMatch("/reset-password/:token");

  console.log(match.params.token);
  const navigate = useNavigate();

  const handleResetPass = (values) => {
    setLoading(true);
    props
      .resetPassword({
        password: values.password,
        token: match.params.token,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate("/auth");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleResetPass(values);
    },
  });

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Studio DMI | Reset Password</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* <Container maxWidth="md"> */}
      <Grid container spacing={0} justifyContent="center">
        <Grid item sm={5} xl={4}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            className={classes.backdrop}
          >
            <img
              src="/authImage.png"
              style={{ height: "100vh", width: "auto", objectFit: "cover" }}
            />
            <Box className={classes.backdropOverlay}>
              <img
                src="/logo1.png"
                width="auto"
                height="auto"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              />
              <br />

              <br />
              <Typography variant="h4" color="textSecondary">
                Mixing and Mastering Studio Led By Luca Pretolesi
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={7} xl={5}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <Box display="flex" justifyContent="center" width="100%">
              <form onSubmit={formik.handleSubmit}>
                <Box
                  display="flex"
                  flexDirection="column"
                  maxWidth="350px"
                  paddingLeft="24px"
                  paddingRight="24px"
                >
                  <Typography align="center" variant="h4" color="initial">
                    Create new password
                  </Typography>
                  <br />
                  <Typography variant="subtitle1" color="initial">
                    Your new password must be different from previous used
                    passwords.
                  </Typography>
                  <br />
                  <Typography variant="body1" color="initial">
                    Password
                  </Typography>
                  <PasswordField
                    id="password"
                    name="password"
                    placeholder="Password"
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />

                  <br />
                  <Typography variant="body1" color="initial">
                    Confirm password
                  </Typography>
                  <PasswordField
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Password"
                    variant="outlined"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />

                  <br />
                  <Button variant="contained" color="primary" type="submit">
                    {loading && <CircularProgress color="secondary" />}
                    {!loading && (
                      <Typography variant="h6" color="textSecondary">
                        RESET PASSWORD
                      </Typography>
                    )}
                  </Button>

                  <br />
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </Container> */}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "20px 100px",
  },
  container: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
}));

export default connect(null, { resetPassword })(ResetPassword);
