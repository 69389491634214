import axios from "../../utils/axios";
import api from "../../utils/api";

export const getAllCourses = (aqp) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.allCourses}`)
      .then((response) => {
        resolve(response.data.courses);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err.response.status);
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};

export const getSingleCourse = (id) => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    console.log("sdj");
    axios
      .get(`${api.getSingle}${id}`)
      .then((response) => {
        resolve(response.data.data);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err.response.status);
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};

export const fetchMyCourse = () => (dispatch, getState) => {
  dispatch({
    type: "START_LOADING",
    payload: {
      loading: true,
    },
  });
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.myCourses}`)
      .then((response) => {
        resolve(response.data.courses);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err.response.status);
        reject(err);
        dispatch({
          type: "START_LOADING",
          payload: {
            loading: false,
          },
        });
      });
  });
};

export const FetchAvailableSessions = (date) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api.fetchAvailableSessions}`, { date })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })
}

export const ApplyCoupon = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`${api.applyCoupon}`, data)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })
}

export const FetchTours = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${api.fetchTours}`)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => reject(err))
  })
}
