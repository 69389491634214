// import logo from "./logo.svg";

import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import Contact from "../components/contact/constctForm";

function Auth() {
  const classes = useStyle();

  const navigate = useNavigate();

  const [signupScreen, setSignupScreen] = useState(false);
  const [loginScreen, setLoginScreen] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [stateError, setStateError] = useState("");

  const handleSaveCard = (event) => {
    setSaveCard(event.target.checked);
  };

  const handleStateSelect = (event, value) => {
    console.log(event, value);
    // setFormAddress(value);
    setSelectedState(event.target.value);
    if (value?.name?.length) {
      setStateError("");
    }
    console.log(selectedState);
  };

  return (
    <div className={classes.Auth}>
      {/* <Container maxWidth="md"> */}
      <Helmet>
        <title>Studio DMI - Contact us for help</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        // alignContent="center"
        // alignItems="center"
      >
        <Grid item xs={12} sm={12} sx={{ height: { xs: "100vh" } }}>
          <Contact />
        </Grid>
      </Grid>
      {/* </Container> */}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    left: 0,
    padding: "20px 100px",
  },
  Auth: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
}));

export default Auth;
