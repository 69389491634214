import React from "react";
import { Route, Routes } from "react-router-dom";
import NoPageFound from "../pages/404";
import Auth from "../pages/auth";
import ContactUs from "../pages/ContactUs";
// import NotFound from "../Pages/Standalone/NotFoundDedicated";
// import LoginDedicated from "../Pages/Standalone/LoginDedicated";
import Edu from "../pages/edu";
import FeaturedWork from "../pages/fw";
import Media from "../pages/media";
import Payment from "../pages/payment";
import Product from "../pages/products/product";
import Profile from "../pages/profile";
import Studios from "../pages/studios";
import Tech from "../pages/tech";
import Home from "../pages/home";
import UserVideoCourse from "../pages/userVideoCourse";
import UserVideoCourses from "../pages/userVideoCourses";
import TourMiami from "../pages/tours/miami";
import TourAmsterdam from "../pages/tours/amsterdam";
import TourWarsaw from "../pages/tours/warsaw";
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
function ApplicationRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/studios" element={<Studios />} />
      <Route exact path="/test" element={<Home />} />
      <Route exact path="/featured-work" element={<FeaturedWork />} />
      <Route exact path="/tech" element={<Tech />} />
      <Route exact path="/products/:id" element={<Product/>} />
      <Route exact path="/tours/miami" element={<TourMiami />} />
      <Route exact path="/tours/amsterdam" element={<TourAmsterdam />} />
      <Route exact path="/tours/warsaw" element={<TourWarsaw />} />
      <Route exact path="/media" element={<Media />} />
      <Route exact path="/auth" element={<Auth />} />
      <Route exact path="/edu" element={<Edu />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route
        exact
        path="/edu/courses/:cid/:vid"
        element={<UserVideoCourse />}
      />
      <Route exact path="/payment" element={<Payment />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/my-videos" element={<UserVideoCourses />} />
      <Route exact path="/my-video/:cid/:vid" element={<UserVideoCourse />} />
      <Route path="*" element={<NoPageFound />} />
      {/* <Route component={NotFound} /> */}
    </Routes>
  );
}

export default ApplicationRoutes;
